import React from "react";
import BillingSection from "./BillingSection";

const currentYear = new Date().getFullYear();

const BillingHeader = () => {
    return (
        <div className="bg-white rounded-2xl h-auto w-10/12 p-6">
            <div className="flex w-full justify-end items-center">
                <span className="text-xl font-bold px-4">Documenti</span>
                <div className='px-3 text-xl text-text-primary bg-white font-semibold inline-block rounded-md border border-text-secondary leading-normal'>
                    {currentYear}
                </div>
            </div>
            <div>
                <BillingSection />
            </div>
        </div>
    )
}

export default BillingHeader;