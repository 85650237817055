import React, { useState, useEffect, useRef } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LoadScriptNext } from '@react-google-maps/api';

import Navbar from './components/Navbar/Navbar';
import Landing from './pages/Landing/Landing';
import Footer from './components/Footer/Footer';
import RequestHarvest from './pages/RequestHarvest/RequestHarvest';
import CustomerArea from "./pages/CustomerArea/CustomerArea";
import ForgotPassword from "./pages/CustomerArea/ForgotPassword";
import SetPassword from "./pages/SetPassword/SetPassword";
import { AuthProvider } from "./contexts/AuthContext";
import RouteGuard from "./guards/routeGuard";
import UserControls from "./components/TopBar/User";
import DashboardWrapper from "./pages/Dashboard/Dashboard";
import Settings from "./pages/Settings/Settings";
import PersonalInfo from "./pages/Settings/PersonalInfo";
import Helper from "./pages/Settings/Helper";
import Billing from "./pages/Settings/Billing";
import Services from "./pages/Landing/Services";
import Insurance from "./pages/Landing/Insurance";
import TabBarWrapper from "./components/TopBar/TabBarWrapper";
import SimpleBar from "simplebar-react";
import Notifications from "./components/Notifications/Notifications";
import { Toaster } from 'react-hot-toast';
import Archive from "./pages/Settings/Archive";
import SidebarTabs from './components/Calendar/SidebarTabs'; // Adjust the import path
import RequestInsurance from "./pages/RequestHarvest/RequestInsurance";

function App() {
  function AppRoutes() {
    const location = useLocation();
    const simpleBarRef = useRef(null);
    const navbarLandingPaths = [
      '/',
      '/services',
      '/insurance',
      '/request-harvest',
      '/request-harvest-insurance',
      '/customer-area',
      '/set-password',
      '/forgot-password',
    ];
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleOpenSidebar = (event) => {
      setSelectedEvent(event);
      setSidebarOpen(true);
    };

    const handleCloseSidebar = () => {
      setSidebarOpen(false);
    };

    useEffect(() => {
      if (simpleBarRef.current) {
        simpleBarRef.current.getScrollElement().scrollTop = 0;
      }
    }, [location]);

    if (navbarLandingPaths.includes(location.pathname)) {
      return (
        <>
          <Navbar />
          <SimpleBar style={{ maxHeight: '100vh' }} ref={simpleBarRef}>
            <main>
              <AuthProvider>
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/insurance" element={<Insurance />} />
                  <Route path="/request-harvest" element={<RequestHarvest />} />
                  <Route path="/request-harvest-insurance" element={<RequestInsurance />} />
                  <Route path="/customer-area" element={<CustomerArea />} />
                  <Route path="/set-password" element={<SetPassword />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                </Routes>
              </AuthProvider>
            </main>
            <Footer />
          </SimpleBar>
        </>
      );
    } else {
      return (
        <div className="App">
          <AuthProvider>
            <TabBarWrapper notifications={setNotificationsOpen} />
            <main>
              <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <Routes>
                  <Route element={<RouteGuard />} >
                    <Route path="/dashboard" element={<DashboardWrapper />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/personal-info" element={<PersonalInfo />} />
                    <Route path="/help" element={<Helper />} />
                    <Route path="/billing-info" element={<Billing />} />
                    <Route path="/archive" element={<Archive />} />
                  </Route>
                </Routes>
              </LoadScriptNext>
            </main>
            <Notifications isOpen={notificationsOpen} onClose={setNotificationsOpen} />
            <Toaster
              position="top-center"
              reverseOrder={false}
              gutter={10}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: '',
                duration: 3000,
                style: {
                  background: '#fff',
                  color: '#363636',
                },
                success: {
                  duration: 3000,
                  theme: {
                    primary: 'green',
                    secondary: 'black',
                  },
                },
              }}
            />
          </AuthProvider>
          <SidebarTabs isOpen={sidebarOpen} onClose={handleCloseSidebar} event={selectedEvent} updateCalendarSize={() => {}} />
        </div>
      );
    }
  }

  return (
    <Router>
      <AppRoutes />
    </Router>
  )
}

export default App;
