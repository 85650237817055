import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faCog, faUser, faBell, faBook } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { useClickAway } from 'react-use';
import { motion } from 'framer-motion';
import './Profile.css';
import { useNavigate } from 'react-router-dom';

const DropdownButton = styled.button.attrs({
  className: 'bg-red-500 rounded-full p-2 w-10 h-10 flex items-center justify-center cursor-pointer shadow-md icon-bg'
})``;

const DropdownMenu = styled(motion.div).attrs({
  className: 'absolute top-14 right-2.5 w-48 bg-white rounded-md p-2 shadow-lg'
})``;

const DropdownItem = styled.a.attrs({
  className: 'block px-4 py-2 rounded hover:bg-gray-100 cursor-pointer'
})``;

// Ora puoi utilizzare questi componenti come prima, ma con classi di Tailwind
const UserControls = ({notifications}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const variants = {
    open: { opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 300, damping: 30 } },
    closed: { opacity: 0, scale: 0.95 }
  }

  useClickAway(dropdownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div className="p-2 flex gap-2" ref={dropdownRef}>
      <DropdownButton onClick={() => notifications(true)}>
        <FontAwesomeIcon icon={faBell} />
      </DropdownButton>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={faUser} />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu
          initial="closed"
          animate="open"
          exit="closed"
          variants={variants}
        >
          <DropdownItem onClick={() => navigate('/settings')}>
            <FontAwesomeIcon icon={faCog} className="mr-2" /> Impostazioni
          </DropdownItem>
          <DropdownItem onClick={() => navigate('/personal-info')}>
            <FontAwesomeIcon icon={faUser} className="mr-2" /> Profilo
          </DropdownItem>
          <DropdownItem onClick={() => navigate('/archive')}>
            <FontAwesomeIcon icon={faBook} className="mr-2" /> Archivio
          </DropdownItem>
          <DropdownItem onClick={logout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" onClick={logout} /> Logout
          </DropdownItem>
        </DropdownMenu>
      )}
    </div>
  );
};

export default UserControls;