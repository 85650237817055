import React, { useEffect, useState } from "react";
import { useDashboardContext } from "../../contexts/DashboardContext";
import { PotatoIcon } from "../Icons";

const SidebarHeader = () => {
    const options = { weekday: 'short', day: 'numeric', month: 'long' };
    const currentDate = new Date().toLocaleDateString('it-IT', options);
    // Abbreviazione giorno della settimana
    const abbreviatedDay = currentDate.split(' ')[0].slice(0, 3); // Prende le prime tre lettere
    const restOfDate = currentDate.split(' ').slice(1).join(' '); // Prende il resto della data
    const formattedDate = `${abbreviatedDay} ${restOfDate}`;

    const { selectedField } = useDashboardContext();

    return (
        <div className="row border-b-2 p-4 bg-white sidebar-header">
            <div className="col-12 d-flex text-end font-light capitalize">
                {formattedDate}
            </div>
            <div className="col-12 d-flex justify-content-between">
                <h1 className="text-2xl font-black sidebar-header-title">{selectedField ? selectedField.name : ''}</h1>
                <div className="flex items-center mt-2">
                    <PotatoIcon className="w-6 h-6 me-2"/> <span className="font-medium text-text-primary">{selectedField ? selectedField.cultivation_type : ''}</span>
                </div>
            </div>
        </div>
    )
}

export default SidebarHeader;