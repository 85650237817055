import React from 'react';
import { CalendarIcon, WindIcon, RainfallIcon, TemperatureIcon, HumidityIcon } from '../Icons/index';

function CardWeatherHeader({ type, title, showIcon = true, iconClass }) {
    let Icon;

    switch (type) {
        case 'temperature':
            Icon = TemperatureIcon;
            break;
        case 'humidity':
            Icon = HumidityIcon;
            break;
        case 'wind':
            Icon = WindIcon;
            break;
        case 'rainfall':
            Icon = RainfallIcon;
            break;
        case 'calendar':
            Icon = CalendarIcon;
            break;
        default:
            Icon = null;
            break;
    }
    return (
        <div className="flex items-center justify-end">
            <span className="ml-2 text-text-primary text-xl font-medium pe-2">{title}</span>
            {showIcon && Icon && <Icon className={iconClass} />}
        </div>
    );
}
export default CardWeatherHeader;