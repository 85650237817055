import React from 'react';

const FeatureItem = ({ Icon, description }) => {
  return (
    <div className="flex felx-row md:flex-col items-center justify-center md:text-center space-y-4 p-4 gap-4 md:gap-0 w-full ">
      <div className="w-24 h-24 p-5 md:p-0 rounded-full border-white md:border-4 border-2 flex items-center justify-center">
        <Icon className="w-14 h-14 md:w-18 md:h-18" /> 
      </div>
      <p className="text-white font-medium feature-description">
        {description}
      </p>
    </div>
  );
};

export default FeatureItem;
