import React from 'react';
import styled from 'styled-components';
import RangeIndicator from './RangeIndicator';
import WarningIndicator from './WarningIndicator';
import './Row.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

const Icon = styled.div.attrs({
    className: `flex items-center rounded-full text-sm font-semibold justify-center`
})`
    width: 3rem; // 32px
    height: 3rem; // 32px
    background-color: ${({ $bgColor }) => $bgColor};
`;

const RowItem = ({id, label, min, max, maxRange, value, measure, warning, color}) => {
    return (
      <div className="flex items-center p-1 h-fit border rounded-full">

        {/* DATI PRELEVATI */}
        <div className="flex w-40 items-center space-x-4">
          <Icon $bgColor={color}>{id}</Icon>
          <span className='font-medium value-name-label'>{label}</span>
        </div>

        {/* RANGE VALORI */}
        <div className="flex-1 text-gray-500 text-center">
          <span className='value-range-label'>{min} - {max} </span>
        </div>
        
        {/* VALORE MISURATO */}
        <div className=" w-48">
          <RangeIndicator min={min} max={max} value={value} maxRange={maxRange} measure={measure}/>
        </div>

        {/* AVVERTIMENTO */}
        <div className='text-center w-12 px-4'>
            {warning && <FontAwesomeIcon icon={faWarning} className='warning-icon'/>}
        </div>
      </div>
    );
  };
  

export default RowItem;