import React from "react";
import './Settings.css';
import { Link } from "react-router-dom";

const Breadcrumb = ({ title, route, backroute, currentPage }) => {
    return (
        <>
            <span className="font-semibolt text-2xl font-bold pb-2">{title}</span>
            <div className="flex flex-row text-text-secondary font-medium">
                <span className="font-medium text-lg"> <Link to={route} className="breadcrumb-back-item">{backroute}</Link> • {currentPage}</span>
            </div>
        </>
    );
}

export {Breadcrumb};