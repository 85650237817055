import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendar.css';
import itLocale from '@fullcalendar/core/locales/it';
import API from '../../services/api';
import toast from 'react-hot-toast';
import SidebarTabs from '../Calendar/SidebarTabs';

const CustomCalendar = () => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const updateCalendarSize = () => {
      if (calendarRef.current) {
        calendarRef.current.getApi().updateSize();
      }
    };

    if (isOpen) {
      setTimeout(updateCalendarSize, 300); // Delay to ensure CSS transition is complete
    } else {
      updateCalendarSize(); // Immediate update when closing
    }
  }, [isOpen]);

  const handleEventClick = (info) => {
    setSelectedEvent({
      title: info.event.title,
      timestamp: info.event.start,
      id: info.event.id,
    });
    setIsOpen(true);
  };

  const handleDatesSet = (info) => {
    let startDate = info.start;

    if (startDate.getDate() !== 1) {
      startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
    }

    const currentMonth = startDate.getMonth() + 1;
    const currentYear = startDate.getFullYear();
    retrieveEvents(currentMonth, currentYear);
  };

  const retrieveEvents = async (month, year) => {
    const loadingToast = toast.loading("Stiamo esplorando l'archivio...", { icon: '🔍' });
    try {
      const response = await API(undefined, undefined).get(`/retrieve-archive-notes?month=${month}&year=${year}`);

      const transformedEvents = response.data.map(event => ({
        id: event.field_id,
        title: event.field_name,
        start: event.timestamp,
      }));

      setEvents(transformedEvents);

      if (transformedEvents.length === 0) {
        toast('Ci spiace ma non abbiamo trovato nessun dato archiviato', { id: loadingToast, icon: '📝' });
      } else {
        toast.success('Abbiamo trovato i tuoi dati!', { id: loadingToast, icon: '👀' });
      }
    } catch (error) {
      console.error('Error retrieving events:', error);
      toast.error('Failed to load events.', { id: loadingToast });
    }
  };

  return (
    <div className="calendar-container rounded-3xl" style={{ display: 'flex', position: 'relative' }}>
      <div className="calendar-wrapper" style={{ flexGrow: 1, transition: 'margin-right 0.3s ease', marginRight: isOpen ? '32rem' : '0' }}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          firstDay={1}
          locale={itLocale}
          events={events}
          height="100%"
          eventClick={handleEventClick}
          selectMirror={true}
          dayMaxEvents={true}
          eventContent={renderEventContent}
          datesSet={handleDatesSet}
          ref={calendarRef}
        />
      </div>
      <SidebarTabs isOpen={isOpen} onClose={() => setIsOpen(false)} event={selectedEvent} updateCalendarSize={() => calendarRef.current.getApi().updateSize()} />
    </div>
  );
};

function renderEventContent(eventInfo) {
  return (
    <div className="fc-event-main-frame">
      <div className="fc-event-title">{eventInfo.event.title}</div>
    </div>
  );
}

export default CustomCalendar;
