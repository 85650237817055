import React, { useEffect, useState } from 'react';
import BackgroundVideoPlayer from 'react-background-video-player';
import Spline from '@splinetool/react-spline';
import './Landing.css';
import FeatureItem from '../../components/LandingElements/FeatureItem';
import { LampLanding, BlockchainLanding, CameraLanding, SensorLanding } from '../../components/Icons';
import { Link, useNavigate } from 'react-router-dom';


const BackgroundVideo = () => {
    const [videoStyle, setVideoStyle] = useState({});

    useEffect(() => {
        function updateVideoStyle() {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const screenRatio = screenWidth / screenHeight;
            const videoRatio = 16 / 9; // Sostituisci con l'aspect ratio reale del tuo video se diverso

            if (screenRatio > videoRatio) {
                // Schermo più largo rispetto al video: adatta la larghezza
                setVideoStyle({
                    width: '100vw',
                    height: `${((100 * screenWidth / videoRatio) / screenHeight)}vh`,
                });
            } else {
                // Schermo più alto rispetto al video: adatta l'altezza
                setVideoStyle({
                    width: `${(100 * screenHeight * videoRatio) / screenWidth}vw`,
                    height: '100vh',
                });
            }
        }

        updateVideoStyle(); // Chiama una volta al montaggio
        window.addEventListener('resize', updateVideoStyle); // Aggiorna al ridimensionamento

        return () => {
            window.removeEventListener('resize', updateVideoStyle);
        };
    }, []);

    return (
        <div className="relative w-full h-screen flex md:items-center overflow-hidden">
            <div className='hidden md:block'>
                <BackgroundVideoPlayer
                    src={[{ src: '/videos/pizza_farm.mp4', type: 'video/mp4' }]}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        ...videoStyle,
                        objectFit: 'cover',
                        zIndex: -1
                    }}
                />
            </div>

            <div className="z-10 text-white px-5 md:px-16 w-100 flex-col md:space-y-12 flex pt-32 md:pt-32 ">
                <h1 className="uppercase font-bold big-title text-start leading-tight">Coltiva il futuro</h1>
                <p className="pt-12 md:pt-4 ps-2 description">
                    <b>Harvest</b> è la soluzione per il monitoraggio continuo sul campo,
                    cambiamento climatico, Parassiti, Nutrienti del terrneno, Vigoria delle colture, Assicurazioni e molto altro ancora.
                    Trasformiamo insieme gli ostacoli e le perdite in opportunità di crescita e successo.
                </p>
            </div>
        </div>
    );
};

const Landing = () => {
    const navigate = useNavigate()

    return (
        <div className="flex justify-center items-center flex-col text-white">
            <div className="relative w-full mobile-img md:block">

                <BackgroundVideo />
            </div>
            <div className="relative w-full flex-col space-y-12 flex">
                <div className='absolute top-0 landing-bg h-full w-full'></div>

                <div className='hidden md:block'>
                    <div className="flex flex-row justify-between px-20 items-center ">


                        <div className="devices-img px-16 pt-32" />
                        <div>
                            <h1 className="text-2xl uppercase font-bold header-subtitle text-start">Harvest</h1>
                            <div className=' mt-10 pt-8 caption ps-2 font-medium '>
                                <p className='description'>
                                    Piattaforma web fornisce agli agricoltori una panoramica<br />completa delle condizioni delle loro coltivazioni,
                                </p>
                                <p className="pt-9 description">
                                    Integrando dati raccolti da sensori sul campo e fornendo<br />previsioni e stime utili per la gestione delle colture.
                                </p>
                                <div className='flex justify-start mt-11'>
                                    <button className=" bg-accent  hover:bg-accent-hover font-medium text-text-primary text-md px-5 py-2 rounded-full" onClick={() => navigate("/request-harvest")}>
                                        Richiedi il servizio
                                    </button>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

                <div className='md:hidden block'>  {/*responive */}
                    <div className="flex flex-col justify-between px-5 items-center mt-12">
                        <div>
                            <h1 className="text-2xl uppercase font-bold header-subtitle text-center">Harvest</h1>
                            <div className='flex justify-center items-center '>
                                <div className="devices-img pt-22">
                                </div>
                            </div>
                            <div className='text-xl caption font-medium '>
                                <p className='description text-center'>
                                    Piattaforma web fornisce agli agricoltori una panoramica completa delle condizioni delle loro coltivazioni,
                                </p>
                                <p className=" pt-3 pb-6 description text-center">
                                    Integrando dati raccolti da sensori sul campo e fornendo previsioni e stime utili per la gestione delle colture.
                                </p>
                                <div className='flex justify-center w-full'>
                                    <Link to={"/request-harvest"}>
                                        <button className=" bg-accent hover:bg-accent-hover font-medium text-text-primary text-xl px-5 py-2 rounded-full w-full">
                                            Richiedi il servizio
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='hidden md:block'>

                    <div className="flex flex-col md:flex-row justify-between px-20 gap-8">

                        <div className='flex flex-col text-end justify-center w-full'>
                            <h1 className="uppercase font-bold header-subtitle text-end">Harvey</h1>
                            <div className='text-xl mt-4 pt-8 ps-2 font-medium'>
                                <p className='description'>
                                    Assistente di campo robotico e modulare, progettato per <br />ottimizzare la gestione delle colture agricole tramite monitoraggio avanzato e continuo.
                                </p>
                            </div>
                        </div>

                        <div className='flex justify-center w-full'>
                            <div className='h-96 w-80  items-center'>
                                <Spline scene="https://prod.spline.design/4WxF98LDhtY5vBdu/scene.splinecode" />

                            </div>
                        </div>

                    </div>

                    <div className='flex items-center flex-row mt-10 mb-20 mx-6 justify-center'>
                        <FeatureItem Icon={CameraLanding} description="Foto periodiche sulla Vigoria delle piante" />
                        <FeatureItem Icon={LampLanding} description="Modelli previsionali sulle colture" />
                        <FeatureItem Icon={BlockchainLanding} description="Dati certificati tramite Blockchain" />
                        <FeatureItem Icon={SensorLanding} description="Analisi con sensori direttamente sul terreno" />
                    </div>
                </div>


                <div className='md:hidden block'> {/*responive */}

                    <div className="flex flex-col justify-between px-5 mt-24">
                        <h1 className="text-2xl uppercase font-bold header-subtitle text-center">Harvey</h1>
                        <div className='flex flex-col text-center justify-center mt-10 font-medium'>
                            <p className='description'>
                                Assistente di campo robotico e modulare, progettato per <br />ottimizzare la gestione delle colture agricole tramite monitoraggio avanzato e continuo.
                            </p>
                        </div>

                        <div className='flex justify-center  py-10 '>
                            <div className='h-80 w-80 items-center'>
                                <Spline scene="https://prod.spline.design/4WxF98LDhtY5vBdu/scene.splinecode" />

                            </div>
                        </div>



                        <div className='justify-start items-start flex flex-col pb-24'>
                            <FeatureItem Icon={CameraLanding} description="Foto periodiche sulla Vigoria delle piante" />
                            <FeatureItem Icon={LampLanding} description="Modelli previsionali sulle colture" />
                            <FeatureItem Icon={BlockchainLanding} description="Dati certificati tramite Blockchain" />
                            <FeatureItem Icon={SensorLanding} description="Analisi con sensori direttamente sul terreno" />
                        </div>




                    </div>
                </div>

            </div>

        </div>
    );
}

export default Landing;
