import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import InvoiceRow from './InvoiceRow';

const BillingSection = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="tabs example">
                        <Tab label="Fatture" value="1" />
                        <Tab label="Archivio Stagioni" value="2" />
                    </Tabs>
                </Box>
                <TabPanel value="1">
                    <div className="px-4 grid grid-cols-4 items-center gap-4 text-text-primary mb-4">
                        <div className="col-span-1">
                            <p className="text-md font-semibold">Nome Fattura</p>
                        </div>
                        <div className="col-span-1">
                            <p className="text-md font-semibold">Erogata il:</p>
                        </div>
                        <div className="col-span-1">
                            <p className="text-md font-semibold">Stato</p>
                        </div>
                    </div>
                    <InvoiceRow />
                </TabPanel>
                <TabPanel value="2">
                    <Typography>Non Esistono ancora i File per questa Sezione</Typography>
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default BillingSection;
