import React from 'react';

function ForecastCardRow({ day, icon, minTemp, maxTemp, humidity }) {
    return (
        <div className="grid grid-cols-5 gap-4 py-1 text-center">
            <div className="flex justify-center items-center text-sm">{day}</div>
            <div className="flex justify-center items-center">{icon}</div>
            <div className="flex justify-center items-center">{minTemp}°</div>
            <div className="flex justify-center items-center">{maxTemp}°</div>
            <div className="flex justify-center items-center">{humidity}%</div>
        </div>
    );
}

export default ForecastCardRow;
