import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext";

const RouteGuard = ({ children }) => {

  const { user } = useAuth();

  if(!user){
    return <Navigate to='/customer-area' replace />;
  }

  return children ? children : <Outlet />;
}

export default RouteGuard;
  