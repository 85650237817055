import React, { useEffect, useRef, useState } from "react";
import API from '../../services/api';
import { GoogleMap, Polygon, Marker, AdvancedMarkerElement } from "@react-google-maps/api";
import styled from '@emotion/styled';
import './Dashboard.css';
import { CircularProgress } from "@mui/material";
import Sidebar from "../../components/Sidebar/Sidebar";
import { DashboardProvider, useDashboardContext } from "../../contexts/DashboardContext";
import Loader from "../../components/Loader/Loader";
import { useAuth } from "../../contexts/AuthContext";
import deviceIcon from '../../assets/HarveySymbol.png'

const DashboardContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
`;

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Dashboard = () => {
    const mapContainerStyle = {
        width: '100%',
        height: '100%',
    };

    const [map, setMap] = useState(null); // ref per la mappa di Google
    const [fields, setFields] = useState([]);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [zoom, setZoom] = useState(16.5);
    const [isLoading, setIsLoading] = useState(true); // Stato per il controllo del caricamento
    const mapContainerRef = useRef(null); // ref per il contenitore della mappa
    const { setTheme } = useAuth();


    const retrieveFields = async () => {
        setIsLoading(true); // Inizia il caricamento
        try {
            const { data: fetchedFields } = await API().get('/fields-devices');
            const fieldsWithParsedCoordinates = fetchedFields.map(fieldData => ({
                ...fieldData,
                field: {
                    ...fieldData.field,
                    coordinates: JSON.parse(fieldData.field.coordinates.replace(/[\s]/g, '')).map(coord => ({
                        lat: parseFloat(coord.lat),
                        lng: parseFloat(coord.lng)
                    })),
                },
                devices: fieldData.devices.map(device => ({
                    lat: parseFloat(device.lat),
                    lng: parseFloat(device.lng)
                }))
            }));
            setFields(fieldsWithParsedCoordinates);
            updateMapCenter(fieldsWithParsedCoordinates);
            setIsLoading(false); // Termina il caricamento
            setTheme("light")
        } catch (error) {
            console.error('Failed to fetch fields:', error);
            setIsLoading(false); // Termina il caricamento anche in caso di errore
        }
    };

    const updateMapCenter = (fieldsWithParsedCoordinates) => {
        let latSum = 0, lngSum = 0, count = 0;
        fieldsWithParsedCoordinates.forEach(field => {
            field.field.coordinates.forEach(coord => {
                latSum += coord.lat;
                lngSum += coord.lng;
                count++;
            });
        });
        if (count > 0) {
            setCenter({
                lat: latSum / count,
                lng: lngSum / count
            });
        }
    };

    useEffect(() => {
        retrieveFields();
    }, []);


    // Gli stili personalizzati per la mappa quando un campo è selezionato
    const darkMapStyles = [
        {
            elementType: 'geometry',
            stylers: [{ color: "#242f3e", opacity: 0.5 }],
        },
    ];

    const baseMapStyles = [{
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
    }];

    const { setSelectedField } = useDashboardContext();
    const [selectedFieldId, setSelectedFieldId] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const manageFieldClick = (field) => {

        if (!isSidebarOpen) {
            setSelectedField(field);
            const fieldCenter = calculateFieldCenter(field.coordinates);
            setCenter(fieldCenter);
            setZoom(18.5);
            setSelectedFieldId(field.fieldID);
        } else {
            setSelectedField(null)
            setSelectedFieldId(null);
            setCenter(center);
            setZoom(16.5);
        }
        setIsSidebarOpen(!isSidebarOpen);
    }
    function calculateFieldCenter(coordinates) {
        // Calcola il centroide del poligono
        let x = 0;
        let y = 0;
        let pointCount = coordinates.length;

        coordinates.forEach(coord => {
            x += coord.lng;
            y += coord.lat;
        });

        let centerLng = x / pointCount;
        let centerLat = y / pointCount;


        const projection = map.getProjection();
        const centerPoint = projection.fromLatLngToPoint(new window.google.maps.LatLng(centerLat, centerLng));

        // Calcola l'offset in base alla dimensione della sidebar
        // e al livello di zoom attuale della mappa.
        const scale = Math.pow(2, map.getZoom());
        const pixelOffset = - (480 / scale) / 10;

        const offsetPoint = new window.google.maps.Point(
            centerPoint.x - pixelOffset,
            centerPoint.y
        );

        // Converte il punto con offset in coordinate lat/lng
        const offsetLatLng = projection.fromPointToLatLng(offsetPoint);
        centerLat = offsetLatLng.lat();
        centerLng = offsetLatLng.lng();


        return { lat: centerLat, lng: centerLng };
    }

    const handleMapLoad = (map) => {
        setMap(map);
    };

    return (

        <DashboardContainer>
            {isLoading ? (
                <LoadingContainer>
                    <Loader />
                </LoadingContainer>
            ) : (
                <>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={zoom}
                        options={{
                            disableDefaultUI: true,
                            zoomControl: false,
                            scrollwheel: selectedFieldId ? false : true,
                            disableDoubleClickZoom: selectedFieldId ? true : false,
                            draggable: selectedFieldId ? false : true,
                            styles: selectedFieldId ? darkMapStyles : baseMapStyles, // Applica stili scuri solo se un campo è selezionato
                            mapTypeId: "satellite",
                            minZoom: 17,
                            maxZoom: 18.5,
                            tilt: 0,
                        }}
                        onLoad={handleMapLoad}
                    >
                        {fields.map(field => (
                            <React.Fragment key={field.field.fieldID}>
                                <Polygon
                                    path={field.field.coordinates}
                                    options={{
                                        fillColor: "red",
                                        fillOpacity: 0.4, // Cambia l'opacità se il campo è selezionato
                                        strokeColor: field.field.color || "blue",
                                        strokeOpacity: 0.4,
                                        strokeWeight: 2,
                                        clickable: true,
                                        draggable: false,
                                        editable: false,
                                        geodesic: true,
                                    }}
                                    onClick={() => manageFieldClick(field.field)}
                                />

                                {field.devices.map(device => (
                                    <Marker
                                        key={device.deviceID + '' + field.fieldID}
                                        position={{ lat: device.lat, lng: device.lng }}
                                        title={device.name}
                                        options={{
                                            clickable: false,
                                            icon: deviceIcon,
                                            scaledSize: new window.google.maps.Size(1, 1), // Dimensione dell'icona
                                            origin: new window.google.maps.Point(0, -10), // Punto di origine dell'icona
                                            anchor: new window.google.maps.Point(0, 0), // Punto di ancoraggio dell'icona
                                        }}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </GoogleMap>
                </>
            )}

            <Sidebar isOpen={isSidebarOpen} />

        </DashboardContainer>
    );
};

const DashboardWrapper = () => {
    return (
        <DashboardProvider>
            <Dashboard />
        </DashboardProvider>
    );
}

export default DashboardWrapper;
