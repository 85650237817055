import React, { useEffect, useState } from 'react';
import GraphItem from '../Graph/GraphItem';
import './GraphCard.css';
import Trend from './Trend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faEquals, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { useDashboardContext } from '../../contexts/DashboardContext';
import Connect from '../../services/api';
import { FarmerWaiting } from '../Icons/index';
import { calculateEMA, determineTrend } from './NdviCalculations';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function GraphCard() {
    const { selectedField } = useDashboardContext();
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [trend, setTrend] = useState({ trend: 'stabile', change: 0 });
    const [plantStatus, setPlantStatus] = useState('');
    const [currentMonth, setCurrentMonth] = useState('');

    const retrieveNdviData = async () => {
        const API = Connect(undefined, undefined, {
            field_id: selectedField.fieldID
        });
        const { data: ndviData } = await API.get('/ndvi');
        const formattedData = ndviData.map(item => ({
            ...item,
            ndvi: parseFloat(item.ndvi_value),
            date: format(new Date(item.timestamp), 'EEE d', { locale: it })
        }));
        setData(formattedData);
        setRenderKey(prevKey => prevKey + 1);

        const emaArray = calculateEMA(formattedData, 3);
        const trendResult = determineTrend(emaArray);
        setTrend(trendResult);

        // Determine plant status based on the last EMA value
        const lastEMA = emaArray[emaArray.length - 1];
        if (lastEMA >= 0.66 && lastEMA <= 1) {
            setPlantStatus('Al top!');
        } else if (lastEMA >= 0.33 && lastEMA < 0.66) {
            setPlantStatus('Sana');
        } else if (lastEMA >= 0 && lastEMA < 0.33) {
            setPlantStatus('Morente');
        } else if (lastEMA >= -1 && lastEMA < 0) {
            setPlantStatus(`Non c'è più nulla da fare `);
        } else {
            setPlantStatus('Valore NDVI fuori intervallo');
        }
    }

    useEffect(() => {
        if (selectedField) {
            retrieveNdviData();
        }
    }, [selectedField]);

    useEffect(() => {
        const month = format(new Date(), 'MMMM', { locale: it });
        setCurrentMonth(month.charAt(0).toUpperCase() + month.slice(1));
    }, []);

    return (
        <div className="bg-white w-full min-h-96 border rounded-3xl p-4">
            <div className='row text-center text-md bg-info p-2 rounded-full font-bold'>
                <h3>
                    Analisi vigoria del terreno
                    <Tippy content={
                        <div className="text-left">
                            <p><strong>NDVI (Normalized Difference Vegetation Index)</strong></p>
                            <p><span style={{ color: '#00FF00' }}>Verde</span>: Vegetazione molto sana (0.66 - 1.0)</p>
                            <p><span style={{ color: '#FFFF00' }}>Giallo</span>: Vegetazione sana (0.33 - 0.65)</p>
                            <p><span style={{ color: '#FFA500' }}>Arancione</span>: Vegetazione stressata (0.0 - 0.32)</p>
                            <p><span style={{ color: '#FF0000' }}>Rosso</span>: Vegetazione morta o assente (-1.0 - 0.0)</p>
                            <p><span style={{ color: '#808080' }}>Grigio</span>: Terreno nudo o neve</p>
                        </div>
                    }>
                        <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className="ml-2 text-gray-500 cursor-pointer"
                        />
                    </Tippy>
                </h3>
            </div>
            <div className='flex flex-row space-x-4 items-center justify-between mt-4'>
                <div className='w-full'>
                    <Trend trend={trend} />
                </div>
                <div className='increasing-info-text flex items-center text-center w-full'>
                    <span className='me-2 text-gray-400 font-medium'>Trend:</span>
                    <span className={`space-x-2 ${trend.trend === 'Incremento' ? 'text-green-500' : trend.trend === 'Decremento' ? 'text-red-500' : 'text-gray-500'}`}>
                        <FontAwesomeIcon icon={trend.trend === 'Incremento' ? faCaretUp : trend.trend === 'Decremento' ? faCaretDown : faEquals} />
                        <span className='font-bold'>
                            {trend.trend === 'Incremento' ? `${trend.change.toFixed(2)}%` : trend.trend === 'Decremento' ? `${trend.change.toFixed(2)}%` : `Stabile (${trend.change.toFixed(2)}%)`}
                        </span>
                    </span>
                </div>
                <div className="flex flex-row items-center p-1 bg-accent rounded-xl px-5 py-2 text-black justify-center space-x-2">
                    <FontAwesomeIcon icon={faCalendar} />
                    <span className="font-medium">{currentMonth}</span>
                </div>
            </div>
            <div className='flex flex-row space-x-4 items-center mt-4'>
                <div className='plant-status-info-text flex items-center text-center'>
                    <span className='me-2 text-gray-400 font-medium'>Stato della Coltura:</span>
                    <span className='font-bold'>{plantStatus}</span>
                </div>
            </div>
            <div className='mt-10'>
                <GraphItem data={data} renderKey={renderKey} dataKey={"ndvi"} setSelectedImage={setSelectedImage} />
            </div>
            {data.length > 0 && (
                <div className='mt-6'>
                    {selectedImage ? (
                        <a href={selectedImage} target="_blank" rel="noreferrer">
                            <img src={selectedImage} alt='placeholder' className='w-full h-72 rounded-3xl' />
                        </a>
                    ) : (
                        <div className="flex items-center flex-col text-center justify-center w-full h-72 gap-4">
                            <FarmerWaiting className='w-32' />
                            <span>Ci spiace ma in questo giorno non c'è nessuna foto 🌱</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default GraphCard;
