import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import './Sidebar.css';
import SidebarHeader from './SidebarHeader';
import SoilSection from "./SoilSection";
import WeatherSection from "./WeatherSection";
import ForecastsSection from "./ForecastsSection";
import NoteSection from "./NoteSection";
import TabBar from "./TabBar";
import { NoteProvider } from "../../contexts/NoteContext";

const sidebarVariants = {
  hidden: { x: '100%', opacity: 1 },
  visible: { x: 0, opacity: 1 }
};

const Sidebar = ({ isOpen }) => {
  const [selectedSection, setSelectedSection] = useState(0);
  const simpleBarRef = useRef(null);

  const renderSection = (selectedSection) => {
    switch (selectedSection) {
      case 0:
        return <SoilSection />;
      case 1:
        return <WeatherSection />;
      case 2:
        return <ForecastsSection />;
      case 3:
        return <NoteSection />;
      default:
        return <SoilSection />;
    }
  }

  useEffect(() => {
    if (simpleBarRef.current) {
      simpleBarRef.current.getScrollElement().scrollTop = 0;
    }
  }, [selectedSection]);

  return (
    <NoteProvider>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="h-full absolute p-2 top-0 right-0 sidebar sidebar-content"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={sidebarVariants}
            transition={{ type: 'spring', duration: 0.2, bounce: 20, damping: 15, mass: 0.5, when: 'beforeChildren' }}
          >
            <div className="w-full h-full bg-white rounded-3xl flex flex-col sidebar-content relative">
              <SidebarHeader />
              {/* SCROLLABLE SECTION */}
              <SimpleBar forceVisible="y" className="flex-grow scroll-bar p-2" ref={simpleBarRef}>
                {renderSection(selectedSection)}
              </SimpleBar>
              {/* TAB BAR */}
              <TabBar selected={selectedSection} setSelected={setSelectedSection} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </NoteProvider>
  );
}

export default Sidebar;
