import React, { useEffect, useState } from 'react';
import "./WeatherCard.css";
import CardWeatherHeader from '../CardWeatherHeader/CardWeatherHeader';
import styled from 'styled-components';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { useDashboardContext } from '../../contexts/DashboardContext';
import Connect from '../../services/api'
import { WindDirection } from '../Icons/index';

const WeatherCardWrapper = styled.div.attrs(({ $borderClass }) => ({
    className: `text-text-primary w-full min-h-36 border-back border-padding ${$borderClass}`
}))``;

function CurrentWeatherCard({ type }) {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true); 
    const { selectedField } = useDashboardContext();

    const retrieveWeatherData = async () => {
        if (!selectedField) {
            console.error("selectedField is null or undefined");
            setIsLoading(false);
            return;
        }

        try {
            const API = Connect(undefined, undefined, {
                field_id: selectedField.fieldID
            });
            const { data: weatherData } = await API.get(`/current-weather?type=${type}`);
            setData(weatherData);
        } catch (error) {
            console.error("Error fetching weather data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedField) {
            setIsLoading(true);
            retrieveWeatherData();
        } else {
            setIsLoading(false);
        }
    }, [type, selectedField]);

    const getBorderClass = () => {
        switch (type) {
            case 'temperature':
                return data.value >= 22 ? 'hot' : 'cold';
            case 'rainfall':
                return data.value >= 5 ? 'heavy-rain' : 'light-rain';
            case 'humidity':
                return data.value >= 60 ? 'high-humidity' : 'low-humidity';
            case 'wind':
                return data.value >= 5 ? 'strong-wind' : 'light-wind';
            default:
                return 'border-default';
        }
    };

    const getIconClass = () => {
        switch (type) {
            case 'temperature':
                return data.value >= 1 ? 'i-hot' : 'i-cold';
            case 'rainfall':
                return data.value >= 1 ? 'i-heavy-rain' : 'i-light-rain';
            case 'humidity':
                return data.value >= 1 ? 'i-high-humidity' : 'i-low-humidity';
            case 'wind':
                return data.value > 1 ? 'i-strong-wind' : 'i-light-wind';
            default:
                return 'i-default';
        }
    };

    const getWindDirection = (deg) => {
        // Adjusting degrees for correct mapping
        if (deg > 337.5 || deg <= 22.5) return 'N';
        if (deg > 22.5 && deg <= 67.5) return 'NE';
        if (deg > 67.5 && deg <= 112.5) return 'E';
        if (deg > 112.5 && deg <= 157.5) return 'SE';
        if (deg > 157.5 && deg <= 202.5) return 'S';
        if (deg > 202.5 && deg <= 247.5) return 'SW';
        if (deg > 247.5 && deg <= 292.5) return 'W';
        if (deg > 292.5 && deg <= 337.5) return 'NW';
        return '';
    };

    const getAdjustedDegree = (deg) => {
        return (deg + 180) % 360;
    };

    const getDescription = () => {
        switch (type) {
            case 'temperature':
                return (
                    <>
                        <div className='pt-1'>Min: {data.air_temperature_min}°</div>
                        <div>Max: {data.air_temperature_max}°</div>
                    </>
                );
            case 'rainfall':
                return <div className='pt-1'>{data.description}</div>;
            case 'humidity':
                return <div className='pt-1'>Media del {data.avg_humidity}%</div>;
            case 'wind':
                return (
                    <>
                        <div className='pt-1'>Raffiche {data.wind_gust_avg} m/s</div>
                        <div className='flex justify-end pt-2'>
                            <WindDirection style={{ transform: `rotate(${getAdjustedDegree(data.wind_deg_avg)}deg)` }} />
                            <span className='ml-2 font-semibold'>{getWindDirection(data.wind_deg_avg)}</span>
                        </div>
                    </>
                );
            default:
                return '';
        }
    };

    const borderClass = getBorderClass();
    const iconClass = getIconClass();

    const renderSkeleton = () => (
        <div>
            <div className='w-full items-end flex-col flex p-2'>
                <Skeleton variant="rounded" width={140} height={15} />
                <Skeleton variant="rounded" width={100} height={10} />
            </div>
            <div className='justify-start w-full p-2'>
                <Skeleton height={50} width={90} />
            </div>
        </div>
    );

    return (
        <WeatherCardWrapper $borderClass={!isLoading ? borderClass : 'border-2'}>
            {!isLoading ? (
                <div className='bg-white h-full border-card flex flex-col justify-between'>
                    <div className="flex flex-col pt-3 pe-3 text-end justify-end">
                        <CardWeatherHeader type={type} title={data.name} iconClass={iconClass} />
                        <div className="text-sm font-light">{getDescription()}</div>
                    </div>
                    <div className="flex flex-row pb-3 ps-3 items-end h-fit">
                        <div className="flex justify-start h-full">
                            <span className="text-5xl font-bold">{data.value ? data.value : '0'}</span>
                        </div>
                        <div className={`flex font-bold ms-2 justify-start ${type === 'temperature' ? "items-start text-3xl" : "items-end text-xl"} h-full`}>
                            {data.symbol}
                        </div>
                    </div>
                </div>
            ) : renderSkeleton()}
        </WeatherCardWrapper>
    );
}

export default CurrentWeatherCard;
