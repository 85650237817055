import React from "react";
import Note from "../Note/Note";


const NoteSection = ({setIsNoteEditing}) => {

    return (
        <div className="w-full felx felx-col">
                <Note setIsNoteEditing={setIsNoteEditing}/>
        </div>
    )
}

export default NoteSection;