import React from "react";
import styled from 'styled-components';
import "../WeatherCards/WeatherCard.css";
import CardWeatherHeader from '../CardWeatherHeader/CardWeatherHeader';

const AirDataWrapper = styled.div.attrs(({ $borderClass }) => ({
    className: `text-text-primary w-full max-h-32 border-back border-padding ${$borderClass}`
}))``;

const AirData = ({ data }) => {
    const getBorderClass = (type, value) => {
        switch (type) {
            case 'temperature':
                if (value >= 1) return 'hot';
                else if (value < -1) return 'cold';
                break;
            case 'rainfall':
                if (value >= 1) return 'heavy-rain';
                else if (value >= -1) return 'light-rain';
                break;
            case 'humidity':
                if (value >= 1) return 'high-humidity';
                else if (value <= -1) return 'low-humidity';
                break;
            case 'wind':
                if (value > 1) return 'strong-wind';
                else if (value <= -1) return 'light-wind';
                break;
            default:
                return 'border-default';
        }
    };

    const getIconClass = (type, value) => {
        switch (type) {
            case 'temperature':
                return value >= 1 ? 'i-hot' : 'i-cold';
            case 'rainfall':
                return value >= 1 ? 'i-heavy-rain' : 'i-light-rain';
            case 'humidity':
                return value >= 1 ? 'i-high-humidity' : 'i-low-humidity';
            case 'wind':
                return value > 1 ? 'i-strong-wind' : 'i-light-wind';
            default:
                return 'i-default';
        }
    };

    const renderDataItem = (type, data, name, description) => {
        const value = data?.value;
        const unit = data?.unit;
        const iconClass = getIconClass(type, value);
        const borderClass = getBorderClass(type, value);

        return (
            <AirDataWrapper $borderClass={borderClass}>
                <div className='bg-white h-full border-card flex flex-col justify-between py-3'>
                    <div className="flex flex-col pt-3 pe-3 text-end justify-end">
                        <CardWeatherHeader type={type} title={name} iconClass={iconClass} />
                        <span className="text-sm font-light">{description}</span>
                    </div>
                    <div className="flex justify-start pb-3 ps-3 items-baseline">
                        <span className="text-3xl font-bold">{value !== undefined ? value : '0'}</span>
                        <span className="text-xl font-bold ms-2">{unit}</span>
                    </div>
                </div>
            </AirDataWrapper>
        );
    };

    const noData = !data ||
        data.air_temperature?.value === undefined ||
        data.average_rain_impact?.value === undefined ||
        data.air_humidity?.value === undefined ||
        data.wind_speed?.value === undefined;

    return (
        <div>
            {noData ? (
                <div className="text-center text-text-secondary flex justify-center items-center h-full ">
                    Nessun dato disponibile
                </div>
            ) : (
                <>
                    <div className="flex flex-row w-full gap-2 pb-2">
                        {renderDataItem('temperature', data.air_temperature, "Temperatura Aria", "Temperatura")}
                        {renderDataItem('rainfall', data.average_rain_impact, "Precipitazioni", "Pioggia")}
                    </div>
                    <div className="flex flex-row w-full gap-2 pb-2">
                        {renderDataItem('humidity', data.air_humidity, "Umidità Aria", "Umidità")}
                        {renderDataItem('wind', data.wind_speed, "Velocità Vento", "Vento")}
                    </div>
                </>
            )}
        </div>
    );
};

export default AirData;
