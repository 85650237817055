import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import './Calendar.css';

const SidebarNote = ({ data = {} }) => {
  const [modalStartIndex, setModalStartIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { image_urls = [], title = '', description = '' } = data; // Destructure with default values

  const totalImages = image_urls.length;

  const openModal = (index) => {
    setModalStartIndex(index);
    setIsModalOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("overflow-hidden");
  };

  const hasNotes = title || description;
  const hasImages = totalImages > 0;

  return (
    <div className="flex flex-col h-full w-full pt-4">
      {!hasImages && !hasNotes && (
        <span className="text-text-primary text-lg font-medium text-center mt-4">
          Non hai caricato nessuna foto e non hai scritto note questo giorno
        </span>
      )}
      {!hasImages && hasNotes && (
        <span className="text-text-primary text-lg font-medium text-center mt-4">
          Non hai caricato nessuna foto questo giorno
        </span>
      )}
      {hasImages && !hasNotes && (
        <span className="text-text-primary text-lg font-medium text-center mt-4">
          Non hai scritto nessuna nota questo giorno
        </span>
      )}
      {hasImages && (
        <>
          <span className="text-text-primary text-lg font-bold">
            Foto:
          </span>
          <div className="mt-2 relative w-full ">
            <div className="flex overflow-x-scroll space-x-2 scrollbar-hide">
              {image_urls.map((url, index) => (
                <div
                  key={index}
                  className="relative flex-shrink-0 w-24 h-24"
                  onClick={() => openModal(index)}
                >
                  <img
                    src={url}
                    alt={`Event ${index + 1}`}
                    className="w-full h-full object-cover rounded cursor-pointer z-30"
                  />
                </div>
              ))}
            </div>
          </div>
          {totalImages > 4 && (
            <div className="flex justify-between items-center pt-4">
              <FontAwesomeIcon icon={faChevronLeft} className="text-gray-500" />
              <span className="text-gray-500">Scorri per vedere più immagini</span>
              <FontAwesomeIcon icon={faChevronRight} className="text-gray-500" />
            </div>
          )}
          {isModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
              <div className="relative z-60">
                <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-white text-2xl font-bold p-11 me-2"
                >
                  &times;
                </button>
                <img
                  src={image_urls[modalStartIndex]}
                  alt={`Modal ${modalStartIndex + 1}`}
                  className="max-w-full max-h-screen p-11"
                />
              </div>
            </div>
          )}
        </>
      )}
      {hasNotes && (
        <>
          <span className="text-2xl pt-6 font-bold text-text-primary pb-2">
            {title}
          </span>
          <span className="text-text-primary text-lg font-medium break-words whitespace-pre-line scrollable-description">
            {description}
          </span>
        </>
      )}
    </div>
  );
};

export default SidebarNote;
