import axios from 'axios';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { USER_POOL_ID, CLIENT_ID } from '../configs/cognito.config';

const Connect = (contentType = 'application/json', url = '', customHeaders = {}) => {
  const userPool = new CognitoUserPool({
    UserPoolId: USER_POOL_ID,
    ClientId: CLIENT_ID
  });

  const tokens = JSON.parse(localStorage.getItem('tokens'));
  const headers = {
    'Content-Type': contentType, 
    ...(tokens?.idToken ? { Authorization: 'Bearer ' + tokens.idToken } : {}),
    ...customHeaders
  }

  const instance = axios.create({
    baseURL: 'https://rp2bl4iued.execute-api.eu-central-1.amazonaws.com/dev',
    headers: headers
  });

  instance.interceptors.request.use(config => config, error => Promise.reject(error));

  instance.interceptors.response.use(response => response, async (error) => {
    if (error?.response?.status === 401 && error.response.data.detail === 'Signature has expired') {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) return Promise.reject(error);
      
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, session) => {
          if (err || !session) return reject(error);

          const newTokens = {
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken()
          };
          localStorage.setItem('tokens', JSON.stringify(newTokens));
          error.config.headers.Authorization = `Bearer ${newTokens.idToken}`;
          resolve(instance.request(error.config));
        });
      });
    }
    return Promise.reject(error);
  });

  return instance;
}

export default Connect;
