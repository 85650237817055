import React, { useEffect, useState } from "react";
import Calendar from "../../components/Calendar/Calendar";
import { useAuth } from "../../contexts/AuthContext";
import { Breadcrumb } from "./Breadcrumb";

const Archive = () => {
    const { setTheme } = useAuth();

    useEffect(() => {
        setTheme("dark");
    }, [setTheme])

    return (
        <div className="flex flex-col w-full mt-32 px-32">
            <div className="flex flex-col justify-start w-full">
                <Breadcrumb title="Archivio" route="/dashboard" backroute="Dashboard" currentPage="Archivio" />
            </div>
            <div className="py-11 flex flex-row justify-center">
                <Calendar />
            </div>
        </div>
    );
};

export default Archive;
