import React, { useEffect, useState } from "react";
import RowItem from "./RowItem/RowItem";
import { CircularProgress } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import Connect from "../../services/api";
import { useDashboardContext } from "../../contexts/DashboardContext";

const SoilAnalysis = () => {
    const [data, setData] = useState([]);
    const { selectedField } = useDashboardContext();
    const retrieveSoilData = async () => {
        const API = Connect(undefined, undefined, {
            field_id: selectedField.fieldID
        })
        const { data: soilData } = await API.get('/latest-soil-data');
        setData(soilData);
    }

    useEffect(() => {
        if (selectedField) {
            retrieveSoilData();
        }
    }, [selectedField])

    const renderSkeletons = () => {
        return Array(5).fill(0).map((_, index) => (
            <div key={index} className="flex items-center p-1 h-fit animate-pulse border rounded-full space-x-4">
                <div className="flex items-center ">
                    <Skeleton circle={true} height={48} width={48} className="p-1" />
                </div>
                <div className="flex-grow items-center">
                    <Skeleton height={30} width={'98%'} />
                </div>
            </div>
        ));
    };

    return (
        <div className="w-full space-y-4">
            {data.length > 0 ? (
                data.map((item) =>
                    <RowItem
                        key={item.value_id}
                        id={item.abbreviation}
                        label={item.label}
                        min={item.range_min}
                        max={item.range_max}
                        maxRange={item.max}
                        value={item.value}
                        measure={item.measure}
                        warning={item.warning === 'true'}
                        color={item.color}
                    />
                ))
                : renderSkeletons()
            }
        </div>
    )
}

export default SoilAnalysis;