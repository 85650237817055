import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { USER_POOL_ID, CLIENT_ID } from '../configs/cognito.config';
import { PeraWalletConnect } from '@perawallet/connect';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {

  const navigate = useNavigate();

  const [user, setUser] = useState(localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null);
  const [cognitoUser, setCognitoUser] = useState();
  const [userAttributes, setUserAttributes] = useState();
  const [theme, setTheme] = useState('dark');
  const [peraWallet, setPeraWallet] = useState(null);
  const [accountAddress, setAccountAddress] = useState("");

  const userPool = useMemo(() => new CognitoUserPool({
    UserPoolId: USER_POOL_ID,
    ClientId: CLIENT_ID
  }), []);

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, result) => {
        if (result) {
          localStorage.setItem('tokens', JSON.stringify(getTokens(result)));
        }
      });
    }
    const peraConnector = new PeraWalletConnect({
      chainId: 416002
    });
    setPeraWallet(peraConnector);
  }, [userPool]);



  useEffect(() => {
    if (peraWallet) {
      // Reconnect to the session when the component is mounted
      peraWallet.reconnectSession().then((accounts) => {
        // Setup the disconnect event listener
        peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);

        if (peraWallet.isConnected && accounts.length) {
          setAccountAddress(accounts[0]);
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [peraWallet]);

  const connectWallet = async () => {
    try {
      await peraWallet.connect().then((address) => {
        // Setup the disconnect event listener
        peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
        setAccountAddress(address[0]);
      });
    } catch (error) {
      console.error(error);
    }
  }

  function handleDisconnectWalletClick() {
    peraWallet.disconnect();
    setAccountAddress(null);
  }

  const login = (username, password, onNewPasswordRequired) => {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          localStorage.setItem('tokens', JSON.stringify(getTokens(result)));
          getCurrentUser().then((response) => {
            localStorage.setItem('user', JSON.stringify(response));
            setUser(response);
            navigate('/dashboard', { replace: true });
          }).catch((err) => {
            localStorage.removeItem('user');
            localStorage.removeItem('tokens');
            setUser(null);
          })
          resolve(result);
        },
        onFailure: (err) => {
          console.log(err);
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          setCognitoUser(cognitoUser);
          setUserAttributes(userAttributes, requiredAttributes);
          navigate('/set-password', { replace: true });
        }
      });
    });
  }

  const logout = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      localStorage.removeItem('user');
      localStorage.removeItem('tokens');
      setUser(null);
      cognitoUser.signOut();
    }
  }

  const onNewPasswordRequired = (newPassword) => {

    console.log("ok", newPassword, userAttributes, cognitoUser);

    delete userAttributes.email_verified;
    delete userAttributes.email;

    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (result) => {
          console.log(result);
          resolve(result);
          navigate('/dashboard', { replace: true });
        },
        onFailure: (err) => {
          console.log(err);
          reject(err);
        }
      });
    });
  }

  const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found'));
        return
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return
        }
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
            return
          }
          const userData = attributes.reduce((acc, attribute) => {
            acc[attribute.Name] = attribute.Value;
            return acc;
          }, {});

          resolve({ ...userData, username: cognitoUser.username });
        })
      })
    })
  }


  const forgotPassword = (username, onInputVerificationCode) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool
      });
      cognitoUser.forgotPassword({
        onSuccess: async (result) => {
          console.log('call result: ' + result);
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode() {
          setCognitoUser(cognitoUser);
          onInputVerificationCode();
        }
      });
    });
  }

  const onNewPassword = (verificationCode, newPassword) => {
    console.log("ok", verificationCode, newPassword, cognitoUser);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (result) => {
          console.log(result);
          resolve(result);
          
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }

  const getTokens = (session) => {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken()
    };
  };

  const getSession = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (!cognitoUser) {
        // console.log('No user found');
        return
      }
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return
        }
        resolve(session);
      });
    });
  }

  const value = {
    user,
    cognitoUser,
    login,
    logout,
    getSession,
    getCurrentUser,
    // refreshSession,
    forgotPassword,
    onNewPasswordRequired,
    onNewPassword,
    theme,
    setTheme,
    connectWallet,
    peraWallet,
    accountAddress
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )

}