import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Dot } from 'recharts';
import { parseISO, compareDesc, compareAsc } from 'date-fns';
import Loader from '../Loader/Loader';

function GraphItem({ data, renderKey, dataKey, setSelectedImage }) {
    const [loading, setLoading] = useState(true);
    const [selectedPoint, setSelectedPoint] = useState(null);

    useEffect(() => {
        if (data.length > 0) {
            setLoading(false);
            const sortedData = data.sort((a, b) => compareAsc(parseISO(a.timestamp), parseISO(b.timestamp)));
            setSelectedPoint(sortedData[sortedData.length - 1]);
            setSelectedImage(sortedData[sortedData.length - 1].ndvi_image);
        }
    }, [data, setSelectedImage]);

    const CustomTooltip = ({ active, payload }) => {
        const point = active && payload && payload.length ? payload[0].payload : selectedPoint;
        if (point) {
            return (
                <div className="custom-tooltip" style={{
                    backgroundColor: '#8884d8',
                    padding: '5px',
                    color: '#fff',
                }}>
                    <p className="label">{`${point[dataKey]}`}</p>
                </div>
            );
        }
        return null;
    };

    const CustomActiveDot = (props) => {
        return (
            <Dot
                {...props}
                stroke="#8884d8"
                fill="#fff"
                r={10}
                strokeWidth={2}
            />
        );
    };

    const handleClick = (e) => {
        if (e && e.activePayload && e.activePayload.length) {
            const point = e.activePayload[0].payload;
            setSelectedPoint(point);
            setSelectedImage(point.ndvi_image);
        }
    };

    if (loading) {
        return (
            <div className="h-full min-h-64 w-full flex justify-center items-center">
                <Loader />
            </div>
        );
    } else {
        return (
            <ResponsiveContainer width="100%" height="100%" className={"h-full min-h-64"} key={renderKey}>
                <AreaChart 
                    data={data} 
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }} 
                    onClick={handleClick} 
                >
                    <XAxis dataKey="date" padding={{ right: 15 }} />
                    <YAxis domain={[0, 1]} allowDataOverflow={true} ticks={[0.2, 0.4, 0.6, 0.8, 1]} />
                    <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'transparent', strokeWidth: 2 }} />
                    <Area
                        type="monotone"
                        dataKey={dataKey}
                        stroke="#8884d8"
                        fillOpacity={1}
                        fill="url(#linearFill)"
                        strokeWidth={4}
                        dot={false}
                        activeDot={<CustomActiveDot />}
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    }
}

export default GraphItem;
