import { faBell, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './Notifications.css';
import { useAuth } from '../../contexts/AuthContext';
import API from '../../services/api';
import Connect from '../../services/api';
import algosdk from 'algosdk';
import toast, { Toaster } from 'react-hot-toast';
import { motion } from 'framer-motion';
import Loader from '../Loader/Loader';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { PeraLogo } from '../Icons/index';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const NotificationRow = ({ data, reload }) => {
    const { peraWallet } = useAuth();

    const handleTransfer = async () => {
        if (!peraWallet.isConnected) {
            toast.error("Wallet non connesso");
            return;
        }

        const accountAddress = peraWallet.connector?.accounts[0];
        if (!accountAddress) {
            toast.error("Indirizzo del wallet non disponibile");
            return;
        }

        const assetId = parseInt(data.notification_id);
        if (isNaN(assetId)) {
            toast.error("ID asset non valido");
            return;
        }

        try {
            const algodServer = 'https://testnet-api.algonode.cloud';
            const algodPort = '443';
            const algodToken = '';
            const algodClient = new algosdk.Algodv2(algodToken, algodServer, algodPort);
            const params = await algodClient.getTransactionParams().do();

            const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                from: accountAddress,
                to: accountAddress,
                amount: 0,
                assetIndex: assetId,
                suggestedParams: params
            });
            const signerTransaction = [{ txn: optInTxn, signers: [accountAddress] }];
            const signedTxn = await peraWallet.signTransaction([signerTransaction]);
            const blob = signedTxn[0];
            if (!blob) {
                throw new Error("No valid transaction blob was returned");
            }

            const sendTx = await algodClient.sendRawTransaction(blob).do();
            const confirmedTxn = await waitForConfirmation(algodClient, sendTx.txId, 4);
            toast.success("Transazione confermata nel round: " + confirmedTxn['confirmed-round']);

            // API call to confirm the transaction on your backend
            const apiConnect = Connect(undefined, undefined, {
                Task_token: data.task_token
            });
            const { data: response } = await apiConnect.get('/confirm-transaction');

        } catch (error) {
            console.error(error);
            toast.error("Errore durante la transazione: " + error.message);
        } finally {
            reload();
        }
    };

    const waitForConfirmation = async (algocl, txId, timeout) => {
        const status = await algocl.status().do();
        const startRound = status['last-round'] + 1;
        let currentRound = startRound;
        while (currentRound < (startRound + timeout)) {
            const pendingInfo = await algocl.pendingTransactionInformation(txId).do();
            if (pendingInfo['confirmed-round'] !== null && pendingInfo['confirmed-round'] > 0) {
                return pendingInfo;
            }
            await algocl.statusAfterBlock(currentRound).do();
            currentRound++;
        }
        throw new Error("Transaction not confirmed after " + timeout + " rounds!");
    };

    return (
        <div className='p-4 w-full border rounded-2xl flex flex-row items-center h-20 my-4'>
            <PeraLogo className='w-16 h-16 pe-3' />
            <div className='w-full'>
                <h3 className="font-semibold pt-1">{data.name}</h3>
                <div className="flex flex-row gap-2 justify-between items-center w-full">
                    <p>{data.field_name}</p>
                    <p> Il {format(new Date(data.timestamp), 'dd-MM-yyyy')} alle ore {format(new Date(data.timestamp), 'HH:mm')}</p>
                    <button className='rounded-xl transfer-button px-4 py-1 hover:bg-green-600' onClick={handleTransfer}>
                        Approva
                    </button>
                </div>
            </div>
        </div>
    );
};

const Notifications = ({ isOpen, onClose }) => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const retrieveNotifications = async () => {
        setIsLoading(true);
        try {
            const { data: notifications } = await API().get('/notifications');
            setNotifications(notifications);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            toast.error("Errore nel recupero delle notifiche: " + error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            retrieveNotifications();
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.5 }
    };

    return (
        <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <motion.div
                className="bg-white p-4 rounded-2xl notification-modal overflow-hidden"
                variants={modalVariants}
            >
                <div className="flex justify-between items-center h-12">
                    <h2 className="text-xl font-semibold">
                        <FontAwesomeIcon icon={faBell} className='me-2' /> Notifiche
                    </h2>
                    <button onClick={() => onClose(false)} className="text-black text-lg">
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <hr className="h-8" />

                        {isLoading ? (
                            <div className='flex flex-col justify-center items-center h-full pb-20'>
                                <Loader />
                                <span className='text-md font-light text-gray-500'>Caricamento in corso...</span>
                            </div>
                        ) : (
                            <div className='h-96'>
                            <SimpleBar className='notification-list'>
                                {notifications.length > 0 ? (
                                    notifications.map((notification, index) => (
                                        <>
                                        <NotificationRow key={index} data={notification} reload={retrieveNotifications}/>
                                        </>
                                    ))
                                ) : (
                                    <div className='flex items-center justify-center text-center h-64 w-full text-gray-800 font-light'>
                                        Nessuna notifica disponibile.
                                    </div>
                                )}
                                </SimpleBar>
                            </div>
                        )}
                    
            </motion.div>
        </motion.div>
    );
};

export default Notifications;
