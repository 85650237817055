import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UploadFileNote from './UploadFileNote';
import Connect from '../../services/api';
import { useDashboardContext } from '../../contexts/DashboardContext';
import toast from 'react-hot-toast';
import { useNoteContext } from '../../contexts/NoteContext'; // Importa il contesto
import PulseLoader from 'react-spinners/PulseLoader';

const FloatingLabelInput = ({ id, label, type = "text", value, onChange, onBlur }) => (
  <div className="relative z-0 w-full mb-5 group">
    <input
      type={type}
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required
      className="block py-1 px-0 w-full text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer"
      placeholder=""
    />
    <label
      htmlFor={id}
      className={`font-bold absolute text-md text-gray-900 dark:text-gray-500 duration-300 transform scale-75 top-1 -z-10 origin-[0] ${value ? 'translate-y-[-20px] text-black dark:text-black' : 'peer-focus:translate-y-[-20px] peer-focus:text-black peer-focus:dark:text-black peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:scale-75 peer-focus:left-0'
        }`}
    >
      {label}
    </label>
  </div>
);

const Note = () => {
  const { selectedField } = useDashboardContext();
  const { setIsNoteEditing, setSubmitForm } = useNoteContext(); // Usa il contesto
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: '',
    description: '',
    images: []
  });
  const [noteId, setNoteId] = useState(null); // State to store the note_id
  const [timestamp, setTimestamp] = useState(null); // State to store the timestamp

  const retrieveNotes = async () => {
    setIsLoading(true);
    try {
      const API = Connect(undefined, undefined, { field_id: selectedField.fieldID });
      const response = await API.get('/note');
      const note = response.data;
      if (note && note.title) {
        setInitialValues({
          title: note.title || '',
          description: note.description || '',
          images: note.image_urls ? note.image_urls.map(url => ({ file: { name: url.split('/').pop() }, url })) : []
        });
        setNoteId(note.note_id); // Set the note_id if it exists
        setTimestamp(note.timestamp); // Set the timestamp if it exists
      } else {
        setInitialValues({
          title: '',
          description: '',
          images: []
        });
        setNoteId(null); // Reset note_id if no note exists
        setTimestamp(null); // Reset timestamp if no note exists
      }
    } catch (error) {
      console.error('Error retrieving notes:', error);
      toast.error('Errore nel recupero delle note.');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedField === null) return;
    retrieveNotes();
  }, [selectedField]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required('Titolo richiesto'),
      description: Yup.string()
    }),
    onSubmit: async (values) => {
      if (isSubmitting) return;
      setIsSubmitting(true);

      const { title, description, images } = values;

      const payload = {
        title,
        description,
        images: images.map(image => ({ fileName: image.file.name }))
      };

      if (noteId) {
        payload.note_id = noteId; // Add note_id to the payload if it exists
      }

      if (timestamp) {
        payload.timestamp = timestamp; // Add timestamp to the payload if it exists
      }

      try {
        const API = Connect(undefined, undefined, { field_id: selectedField.fieldID });
        const response = await API.post('/save-notes', payload);
        const { presigned_urls } = response.data;

        // Carica solo se ci sono immagini
        if (images.length > 0) {
          await Promise.all(
            images.map((image, index) =>
              fetch(presigned_urls[index], {
                method: 'PUT',
                headers: {
                  'Content-Type': image.file.type
                },
                body: image.file
              })
            )
          );

          toast.success('Images uploaded successfully!');
        }

        retrieveNotes(); // Ricarica le note dopo averne salvata una nuova
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Error uploading images');
      } finally {
        setIsNoteEditing(false);
        setIsSubmitting(false);
      }
    }
  });

  useEffect(() => {
    setIsNoteEditing(formik.dirty);
    setSubmitForm(() => formik.handleSubmit); // Imposta la funzione di submit
  }, [formik.dirty, formik.handleSubmit, setIsNoteEditing, setSubmitForm]);

  if (isLoading) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <PulseLoader color="rgb(44, 62, 80)" className='pt-80 mt-6 ' />
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col w-full h-fit pt-5">
      <div className='border-2 rounded-3xl p-3'>
        <div className='pt-5'>
          <FloatingLabelInput
            label="Titolo:"
            id="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.title && formik.errors.title ? (
            <div className="text-red-500">{formik.errors.title}</div>
          ) : null}
        </div>
        <p className='font-bold pt-5'> Descrivi cos'hai fatto oggi: </p>
        <textarea
          id="description"
          name="description"
          className="w-full border-0 border-b-2 border-transparent focus:border-b-2 focus:border-gray-300 focus:outline-none"
          rows={11}
          placeholder="Scrivi qui..."
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
        </textarea>
        {formik.touched.description && formik.errors.description ? (
          <div className="text-red-500">{formik.errors.description}</div>
        ) : null}
      </div>
      <UploadFileNote
        images={formik.values.images}
        setFieldValue={formik.setFieldValue}
      />
    </form>
  );
}

export default Note;
