import React from "react";
import { Link, useNavigate } from "react-router-dom";
import './Landing.css';
import { ServicesData, ServicesArrow, ServicesServer, ServicesWebsite, BlockchainLanding } from "../../components/Icons";
import Blockchain from "./Blockchain";

const Services = () => {
  return (
    <div>
      <div className="z-10 service-img flex flex-col text-white">
        <div className="px-5 md:px-16 pt-36">
          <h1 className="uppercase font-bold header-subtitle-md text-start pb-0 md:pb-12 leading-tight">Harvest Monitoring</h1>
          <div className="flex justify-center pb-28 py-12">
            <div className="flex flex-col md:flex-row bg-white rounded-2xl md:rounded-3xl items-center justify-between w-full md:w-full p-8 mx-0 md:mx-52 ">
              <div className="rounded-xl md:rounded-2xl w-full h-72 md:w-[500px] md:h-full p-0 md:p-4 mx-0 ">
                <div className="harvey-service md:rounded-2xl rounded-xl"/>
              </div>
              <div className="flex flex-col w-full h-fit justify-center">
                <h2 className="text-xl font-medium text-start pt-5 text-text-secondary">A partire da</h2>
                <span className="text-3xl font-bold text-start text-text-primary">650 € / Anno*</span>
                <span className="details-price font-regular text-text-primary">(*Il prezzo può variare in base agli ettari)</span>
                <ul className="font-light text-text-primary mt-8 service-description">
                  <li>Monitoraggio delle coltivazioni</li>
                  <li>Dati registrati in blockchain</li>
                  <li>Piattaforma Harvest</li>
                  <li>Almeno 1 Harvey</li>
                  <li>Modelli Previsionali</li>
                  <li>Assistenza</li>
                </ul>
                <div className="flex  w-full mt-8 items-end h-fit">
                  <Link to="/request-harvest" className="w-full flex justify-center md:justify-end">
                    <button className="bg-accent hover:bg-accent-hover font-medium text-text-primary text-md px-5 py-2 rounded-full md:w-fit w-full">
                      Richiedi il servizio
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <h2 className="text-2xl font-bold header-description text-center md:text-start pb-12">Come funziona?</h2>
          <div className="flex flex-col md:grid md:grid-cols-5 gap-2 items-center justify-items-center h-full px-8">
            <div className="flex flex-col items-center space-y-2">
              <ServicesData className="md:w-20 md:h-20 w-24 h-24" />
              <p className="text-white font-medium text-sm md:text-xl text-center feature-description leading-tight">Harvey raccoglie periodicamente i dati</p>
            </div>
            <div className="py-4">
              <ServicesArrow className="md:w-10 md:h-10 w-12 h-12 rotate-90 md:rotate-0" />
            </div>
            <div className="flex flex-col items-center space-y-2">
              <ServicesServer className="md:w-20 md:h-20 w-24 h-24" />
              <p className="text-white font-medium text-sm md:text-xl text-center feature-description leading-tight">Il Server li elabora e fa da tramite</p>
            </div>
            <div className="py-4">
              <ServicesArrow className="md:w-10 md:h-10 w-12 h-12 rotate-90 md:rotate-0" />
            </div>
            <div className="flex flex-col items-center col-span-1 space-y-2">
              <ServicesWebsite className="md:w-20 md:h-20 w-24 h-24" />
              <p className="text-white font-medium text-sm md:text-xl text-center feature-description leading-tight">Sul sito possono essere letti con semplicità</p>
            </div>
            <div className="col-start-1 col-span-1 py-4">
              <ServicesArrow className="md:w-10 md:h-10 w-12 h-12 rotate-90" />
            </div>
            <div className="col-start-1 flex flex-col items-center pb-28 space-y-2">
              <BlockchainLanding className="md:w-24 md:h-24 w-32 h-32" />
              <p className="text-white font-medium text-sm md:text-xl text-center feature-description leading-tight">Se rileva un evento grave lo registra sulla blockchain</p>
            </div>
          </div>
        </div>
      </div>
      <Blockchain />
    </div>
  );
}

export default Services;
