import React from "react";
import Spline from '@splinetool/react-spline';
import './Landing.css';

const Blockchain = () => {
  return (
    <div className="z-10 blockchain-img flex flex-col text-white">
      <div className="hidden md:block px-16 pt-36">
        <h1 className="text-4xl uppercase font-bold header-subtitle text-start pb-12">Blockchain</h1>
        <div className="flex flex-row gap-x-20">
          <div className="flex flex-col justify-between pb-28 w-full space-y-12">
            <section className="w-full">
              <h2 className="text-2xl font-bold text-start header-description pt-16">Dati certificati</h2>
              <div className="text-xl pt-8 caption font-medium">
                <p className="pb-3 description">
                  Harvest adotta la blockchain di Algorand per i suoi benefici ecologici <br /> e per la sicurezza e l'efficienza.
                </p>
                <p className="description">
                  La Blockchain serve per certificare e garantire la sicurezza delle informazioni relative alle colture. <br /> Le informazioni vengono registrate come transazioni, legate direttamente agli agricoltori, <br /> assicurando così proprietà dei dati.
                </p>
              </div>
            </section>
            <section>
              <h2 className="text-2xl font-bold text-end header-description">Carbon Negative</h2>
              <div className="text-xl pt-8 caption font-medium text-end">
                <p className="pb-3 description">
                  Algorand compensa l'energia consumata acquistando crediti di carbonio, che sono investiti in progetti ambientali come la riforestazione.
                  Questo riduce anche le emissioni di CO2 associate alle sue operazioni, sostenendo così iniziative che hanno un impatto positivo sull'ambiente.
                </p>
              </div>
            </section>
          </div>

          <div className="flex w-50 justify-end">
            <div className=" w-full h-full">
              <Spline scene="https://prod.spline.design/Bub9p5h9TC3Mj6Wu/scene.splinecode" />
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden block px-5 pt-24">
        <h1 className="text-4xl uppercase font-bold header-subtitle-md text-center pb-12">Blockchain</h1>
        <div className="flex flex-row justify-center items-center space-y-12">
          <div className="flex flex-col justify-between items-center pb-5 w-full space-y-12">
            <section className="text-center">
              <h2 className="text-2xl font-bold text-center header-description">Dati certificati</h2>
              <div className="text-xl pt-8 caption font-medium">
                <p className="pb-3 description">
                  Harvest adotta la blockchain di Algorand per i suoi benefici ecologici e per la sicurezza e l'efficienza.
                </p>
                <p className="description">
                  La Blockchain serve per certificare e garantire la sicurezza delle informazioni relative alle colture. Le informazioni vengono registrate come transazioni, legate direttamente agli agricoltori, assicurando così proprietà dei dati.
                </p>
              </div>
            </section>
            <div className="w-80 h-80">
              <Spline scene="https://prod.spline.design/Bub9p5h9TC3Mj6Wu/scene.splinecode" />
            </div>
            <section className="text-center">
              <h2 className="text-2xl font-bold header-description">Carbon Negative</h2>
              <div className="text-xl pt-8 caption font-medium">
                <p className="pb-3 description">
                  Algorand compensa l'energia consumata acquistando crediti di carbonio, che sono investiti in progetti ambientali come la riforestazione.
                  Questo riduce anche le emissioni di CO2 associate alle sue operazioni, sostenendo così iniziative che hanno un impatto positivo sull'ambiente.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blockchain;
