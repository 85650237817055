import React, { createContext, useContext, useState } from 'react';

const NoteContext = createContext();

export const useNoteContext = () => useContext(NoteContext);

export const NoteProvider = ({ children }) => {
  const [isNoteEditing, setIsNoteEditing] = useState(false);
  const [submitForm, setSubmitForm] = useState(null);

  return (
    <NoteContext.Provider value={{ isNoteEditing, setIsNoteEditing, submitForm, setSubmitForm }}>
      {children}
    </NoteContext.Provider>
  );
};
