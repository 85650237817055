import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/animations/scale.css'
import styled from 'styled-components';

import './RangeIndicator.css';

const RangeIndicatorWrapper = styled.div.attrs({
  className: 'relative'
})``;

const PillBar = styled.div.attrs({
    className: "w-full rounded-full h-4 mx-2"
  })`
  background: ${({ $min, $max, $maxRange }) => {
    const start = ($min / $maxRange) * 100;
    const end = ($max / $maxRange) * 100;
    const mid1 = start + (end - start) * 0.10;
    const mid2 = start + (end - start) * 0.5;
    const mid3 = end - (end - start) * 0.10;

    return `linear-gradient(to right, 
      #FF6D6D ${start}%, 
      #E3C764 ${mid1}%, 
      #63DB56 ${mid2}%, 
      #E3C764 ${mid3}%, 
      #FF6D6D ${end}%)
    `;
  }};
`;

const Indicator = styled.div`
  position: absolute;
  width: 0.14rem;
  height: 1rem;
  top: 0;
  left: ${({ $valuePosition }) => $valuePosition}%;
  background-color: #4318FF;
`;



const RangeIndicator = ({ min, max, value, maxRange, measure }) => {
  // Assicurati che tutti i valori siano numeri
  const safeMin = Number(min) || 0;
  const safeMax = Number(max) || 0;
  const safeValue = Number(value) || 0;
  const safeMaxRange = Number(maxRange) || 1; // Sostituisci 100 con il valore predefinito appropriato se necessario


  // Calcola la posizione percentuale del valore e dei limiti del range
  const valuePosition = (safeValue / safeMaxRange) * 100;
  const minPosition = (safeMin / safeMaxRange) * 100;
  const maxPosition = (safeMax / safeMaxRange) * 100;

  if (safeValue > safeMaxRange) {
    return (
      <RangeIndicatorWrapper className='text-center'>
        <span className="text-red-500 text-sm"><b>{safeValue} {measure}</b> valore molto elevato</span>
      </RangeIndicatorWrapper>
    )
  } else {
    return (
      <RangeIndicatorWrapper>
        <div className="flex justify-between items-center pill-bar">
        <PillBar $min={safeMin} $max={safeMax} $maxRange={safeMaxRange}>
            <Tippy content={`${value} ${measure}`} placement="top" arrow={false} visible={true} animation="scale"
              theme='soil'
              popperOptions={{
                strategy: 'fixed',
                modifiers: [
                  {
                    name: 'flip',
                    enabled: false // Disabilita il flip automatico del tooltip
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'viewport'
                    }
                  }
                ]
              }}
              appendTo={() => document.querySelector('.pill-bar')}
            >
              <Indicator $valuePosition={valuePosition} />
            </Tippy>
          </PillBar>
        </div>
      </RangeIndicatorWrapper>
    );
  }
};

export default RangeIndicator;
