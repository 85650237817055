import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown, faCircleChevronUp, faEquals } from '@fortawesome/free-solid-svg-icons';
import elements from './elements.json';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import './Element.css';

const StyledCard = styled(motion.div, {
    shouldForwardProp: (prop) => !['backgroundColor'].includes(prop),
})`
    background: ${(props) => props.backgroundColor};
    width: 10.7rem;
    height: 10rem;
`;

const Circle = styled('div', {
    shouldForwardProp: (prop) => !['backgroundColor'].includes(prop),
})`
    background-color: ${(props) => props.backgroundColor};
    border-radius: 50%;
    width: 4rem; 
    height: 4rem;  
    display: flex;
    align-items: center;
    justify-content: center;
`;

const State = ({ isGood, type }) => {
    switch (type) {
        case 'up':
            return (
                <>
                    <FontAwesomeIcon icon={faCircleChevronUp} className={isGood ? "text-green-500" : "text-red-500"} />
                </>
            );
        case 'equal':
            return (
                <>
                    <FontAwesomeIcon icon={faEquals} className={isGood ? "text-green-500" : "text-red-500"} />
                </>
            );
        case 'down':
            return (
                <>
                    <FontAwesomeIcon icon={faCircleChevronDown} className={isGood ? "text-green-500" : "text-red-500"} />
                </>
            );
        default:
            break;
    }
};

const waveVariants = {
    hidden: { y: '120%' },
    visible: {
        y: '0',
        transition: {
            duration: 2,
            ease: [0.22, 1, 0.8, 1],
            type: 'spring',
        }
    }
}


const Element = ({ element }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        setTimeout(() => {
            const dati = elements[element];
            if (dati) {
                setData({
                    ...dati
                });
                setLoading(false);
            }
        }, 2000);
    }, [element]);

    const fillHeight = 0;

    const renderSkeletons = () => {
        return (
            <div className="flex flex-col items-center p-2 rounded-3xl border-2 w-card h-40">
            <div className='w-full justify-end flex'>
                <Skeleton circle={true} height={20} width={20} className="p-1" />
                </div>
                <div className='justify-center pb-3'>
                <Skeleton circle={true} height={60} width={60} className="p-1" />
                </div>
                <div className='flex flex-col items-center'>
                    <Skeleton variant="rounded" width={100} height={15} />
                    <Skeleton variant="rounded" width={90} height={15} />
                </div>
            </div>
        );
    };

    if (!loading) {
        return (
            <StyledCard
                backgroundColor={data.backgroundColor}
                className="rounded-xl p-2 flex flex-col justify-between relative overflow-hidden"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >

                <motion.svg
                    initial="hidden"
                    animate="visible"
                    variants={waveVariants}
                    className="w-full absolute -bottom-2 right-0 rounded-b-xl"
                    style={{
                        height: `${fillHeight}%`,
                        background: `url(${element.toLowerCase()}_wave.svg)`, // Inserisci il percorso al tuo SVG
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom',
                        backgroundSize: 'cover',
                    }}
                />
                <div className="relative z-10">
                    <div className='symbol flex justify-end align-top text-sm'>
                        - -
                    </div>
                    <div className='flex flex-col items-center justify-between w-full'>
                        <div className='w-full flex justify-center'>
                            <Circle backgroundColor={data.circleColor}>
                                <img src={`${data.icon}.svg`} alt="icona" className='p-2' style={{ width: '60%', height: 'auto' }} />
                            </Circle>
                        </div>
                        <div className='text-center mt-2 w-full'>
                            <div className='text-lg font-medium'>{element}</div>
                            <div className='text-m font-light w-full'>
                                <div className='flex flex-row items-center justify-center space-x-2'>
                                    <div className='col text-end'>
                                        - -
                                    </div>
                                    <div className='col text-start'>
                                        {data.unit}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </StyledCard>
        );
    } else {
        return (
            <div>
                {renderSkeletons()}
            </div>
        );
    }
}

export default Element;