import React, { useState, useEffect } from 'react';
import ForecastCardRow from './ForecastCardRow';
import './WeatherCard.css';
import { useDashboardContext } from '../../contexts/DashboardContext';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faCloudSun, faCloud, faCloudShowersHeavy, faCloudSunRain, faCloudBolt, faSnowflake, faSmog } from '@fortawesome/free-solid-svg-icons';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';


function ForecastCard() {
  const [forecasts, setForecasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedField } = useDashboardContext();

  function formatWeatherData(weatherData) {
    return weatherData.map(data => {
      const date = new Date(data.dt * 1000);
      const dayRaw = date.toLocaleDateString('it-IT', { weekday: 'long', day: 'numeric' });
      const day = dayRaw.charAt(0).toUpperCase() + dayRaw.slice(1);
      const maxTemp = parseFloat(data.temp.max.toFixed(1));
      const minTemp = parseFloat(data.temp.min.toFixed(1));
      const temperature = data.temp.day;
      const humidity = parseFloat(data.humidity.toFixed(1));
      const icon = data.weather[0].icon;

      return { day, minTemp, maxTemp, temperature, humidity, icon };
    });
  }

  const retrieveWeatherForecast = async () => {
    const apiKey = "f638a8112345fa05e6662a3810886002";
    const lat = selectedField.coordinates[0].lat;
    const lng = selectedField.coordinates[0].lng;

    const weatherData = localStorage.getItem('weatherData');
    const lastFetch = localStorage.getItem('lastFetch');

    if (weatherData && lastFetch && (new Date().getTime() - new Date(parseInt(lastFetch)).getTime()) / (1000 * 60 * 60) < 6) { 
      setForecasts(JSON.parse(weatherData));
      setIsLoading(false);
      return;
    }

    axios.get(`https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lng}&exclude=current,minutely,hourly,alerts&units=metric&lang=it&appid=${apiKey}`)
      .then(response => {
        const dailyData = response.data.daily.slice(1, 6); // Get data for the next 5 days excluding today
        const formattedData = formatWeatherData(dailyData);
        localStorage.setItem('weatherData', JSON.stringify(formattedData));
        localStorage.setItem('lastFetch', new Date().getTime().toString());
        setForecasts(formattedData);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Failed to fetch the weather data', error);
      });
  };

  useEffect(() => {
    if (selectedField) {
      retrieveWeatherForecast();
    }
  }, [selectedField]);

  const renderIcon = (icon) => {
    switch (icon) {
      case '01d':
        return <FontAwesomeIcon icon={faSun} /> //cielo sereno
      case '02d':
        return <FontAwesomeIcon icon={faCloudSun} /> //poche nuvole
      case '03d':
        return <FontAwesomeIcon icon={faCloud} /> //nubi sparse
      case '04d':
        return <FontAwesomeIcon icon={faCloud} /> //nuvole spezzate
      case '09d':
        return <FontAwesomeIcon icon={faCloudSunRain} /> // e sole pioggia
      case '10d':
        return <FontAwesomeIcon icon={faCloudShowersHeavy} /> //pioggia
      case '11d':
        return <FontAwesomeIcon icon={faCloudBolt} /> //temporale
      case '13d':
        return <FontAwesomeIcon icon={faSnowflake} /> //neve
      case '50d':
        return <FontAwesomeIcon icon={faSmog} /> //nebbia
      default:
        return '';
    }
  }

  const renderSkeletons = () => {
    return Array(5).fill(0).map((_, index) => (
      <div key={index} className="flex items-center p-1 m-1 animate-pulse rounded-full space-x-4">
        <div className="flex flex-col items-center p-1 ">
          <Skeleton height={25} width={480} />
        </div>
      </div>
    ));
  };

  return (
    <div className="weather-card pt-2 px-2 rounded-3xl min-h-full text-gray-700 min-w-96 border-2 ">
      <div className="bg-info py-2 rounded-full w-full ">
        <div className="grid grid-cols-5 gap-4 py-1 text-center font-bold">
          <div className="flex justify-center items-center">Giorno</div>
          <div className="flex justify-center items-center">Previsione</div>
          <div className="flex justify-center items-center">Minima</div>
          <div className="flex justify-center items-center">Massima</div>
          <div className="flex justify-center items-center">Umidità</div>
        </div>
      </div>

      {isLoading ? (
        <div>
          {renderSkeletons()}
        </div>
      ) : (
        <div className="mt-2 h-full w-full item-center py-2 font-bold space-y-4">
          {forecasts.map((forecast) => (
            <ForecastCardRow
              key={forecast.day}
              day={forecast.day}
              icon={renderIcon(forecast.icon)}
              minTemp={forecast.minTemp}
              maxTemp={forecast.maxTemp}
              humidity={forecast.humidity}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ForecastCard;