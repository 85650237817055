import React, { useState } from "react";
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

// Componente che rappresenta una singola riga di fattura
const InvoiceRow = ({ invoice }) => {
    return (
        <div className="px-4 py-1 grid grid-cols-4 items-center gap-4 border border-text-secondary rounded-3xl bg-white ">
            <div className="col-span-1">
                <p className="text-lg">{invoice.name}</p>
            </div>
            <div className="col-span-1">
                <p className="text-lg">{invoice.date}</p>
            </div>
            <div className="col-span-1">
                <p className="text-lg">{invoice.status}</p>
            </div>
            <div className="col-span-1">
                <IconButton aria-label="download pdf" size="large">
                    <FontAwesomeIcon icon={faFilePdf} />
                </IconButton>
            </div>
        </div>
    );
};

// Componente genitore che gestisce un elenco di fatture
const InvoicesList = () => {
    const [invoices, setInvoices] = useState([

    ]);

    if (invoices.length === 0) {
        return <div className="px-4 py-10 text-lg">Non sono ancora state registrate fatture.</div>;
    }

    return (
        <div>
            {invoices.map(invoice => (
                <InvoiceRow key={invoice.id} invoice={invoice} />
            ))}
        </div>
    );
}

export default InvoicesList;
