import React from "react";
import { GoodPlant, DyingPlant, StablePlant } from '../Icons/index';

const Trend = ({ trend }) => {
    let icon, message;

    switch (trend.trend) {
        case 'Incremento':
            icon = <GoodPlant />;
            message = "Sta andando bene";
            break;
        case 'Decremento':
            icon = <DyingPlant />;
            message = "Sta peggiorando";
            break;
        case 'Stabile':
        default:
            icon = <StablePlant />;
            message = "È rimasta stabile";
            break;
    }

    return (
        <div className="flex flex-row items-center py-1 bg-selected rounded-full space-x-2 justify-between ps-1 pe-4">
            <div className="w-8 h-8 rounded-full bg-yellow-100 text-white flex justify-center items-center">
                <div className="w-5 h-5 rounded-full text-center">
                    {icon}
                </div>
            </div>
            <span className="font-bold trend-text">{message}</span>
        </div>
    );
}

export default Trend;


