import React, { useEffect } from "react";
import lottie from "lottie-web";

const Loader = () => {
    useEffect ( () => {
        lottie.loadAnimation({
            container: document.querySelector('.loader'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('./Spiga_V2.json')
        });
    })

    return (
        <div className="flex justify-center items-center h-20 w-24">
            <div className="loader">
            </div>
        </div>
    );
}

export default Loader;