import React from "react";
import './Landing.css';
import { HarvestFinance } from "../../components/Icons";
import { Link } from "react-router-dom";

const Insurance = () => {
  return (
    <div className="insurance-img-bottom">
      <div className="insurance-img-top text-white">
        <div className="px-5 md:px-16 pt-32 md:pt-56">
          <h1 className="uppercase font-bold md:pt-24 big-title-md leading-tight text-start">
            Rivoluziona il tuo approccio assicurativo
          </h1>
          <p className="pt-12 md:pt-8 caption-md ps-2">
            <b>Harvest<i>finance</i></b> ti offre una visione chiara e dettagliata dell'andamento delle perdite agricole nel tempo.
            <br />
            Con grafici e analisi approfondite, puoi comprendere meglio i trend e adattare le tue strategie assicurative di conseguenza.
          </p>
        </div>
      </div>
      <div className="px-5 md:px-16 md:mt-32 mt-64">
        <div className="flex flex-col lg:flex-row w-full gap-8">
          <div className="flex justify-center items-center md:py-8">
            <div className="insurance-devices-img"></div>
          </div>
          <div className="flex flex-col text-xl mt-0 md:mt-10 pt-2 md:pt-16 caption ps-2 font-regular justify-center text-white text-center md:text-start">
            <p className="description">
              Può trasformare il modo in cui proteggi gli agricoltori.
              <br />
              Grazie alla sua tecnologia avanzata e all'analisi dei dati, è possibile ottenere una comprensione più precisa delle perdite causate da eventi climatici estremi.
            </p>
            <p className="pt-6 description">
              In questo modo potete calcolare i premi e fornire coperture più adeguate agli agricoltori,
              aumentando così l'efficienza e la precisione delle operazioni.
            </p>
            <div className="flex justify-center md:justify-start pt-4 md:mt-12 mt-8 w-full md:w-fit">
              <Link to="/request-harvest-insurance" className="w-full">
                <button className="bg-accent font-medium text-text-primary text-xl md:text-md px-5 py-1 rounded-full w-full md:w-fit h-12">
                  Richiedi informazioni
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center pt-40 md:pb-52">
          <div className="flex header-description md:header-subtitle gap-2 md:gap-5 pb-4 md:pb-8">
            <HarvestFinance className="w-96 h-12" />
          </div>
          <p className="header-description-insurance text-center pb-32 md:pb-0 font-bold text-white mt-4 md:mt-0">
            Offriamo alle assicurazioni dati sicuri e certificati, migliorando la gestione delle polizze agricole con previsioni precise e registrazioni su blockchain.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Insurance;
