import React from "react";
import { motion } from "framer-motion";
import "./Footer.css";
import logo from '../../logo-white.svg';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="relative footer min-h-20 text-center text-white flex flex-col justify-center items-center space-y-6">
            <div className="flex flex-col md:flex-row justify-between w-full items-center px-5 md:px-20 gap-4 lg:gap-0">
                <div className="flex flex-col text-start w-full md:justify-center md:items-start items-center">
                    <img src={logo} alt="logo" className="w-32" />
                </div>
                <div className="flex justify-center items-center h-full w-full">
                    <p className="text-md mt-auto">© {currentYear} - Harvest | All Rights Reserved</p>
                </div>
                <div className="hidden md:flex flex-row items-center text-end justify-end w-full">
                    <div className="hidden md:flex flex-col">
                        <div className="flex flex-col">
                            <p>
                                <motion.a 
                                    href="mailto:info.harvestmonitoring@gmail.com" 
                                    className="relative inline-block text-white"
                                    initial="initial"
                                    whileHover="hover"
                                    animate="initial"
                                >
                                    info.harvestmonitoring@gmail.com
                                    <motion.span
                                        className="absolute bottom-0 left-0 right-0 h-[2px] bg-white origin-right"
                                        variants={{
                                            initial: { scaleX: 0, transformOrigin: "right" },
                                            hover: { scaleX: 1, transformOrigin: "right" },
                                        }}
                                        transition={{ duration: 0.3 }}
                                    />
                                </motion.a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
