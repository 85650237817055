import React from "react";
import UserControls from "./User";
import './Profile.css';
import logo from '../../logo-white.svg'
import { LogoWhite ,LogoBlack } from "../Icons";
import { useAuth } from "../../contexts/AuthContext";

const TabBarWrapper = ({notifications}) => {
    const {theme} = useAuth();

    return (
        <div className="w-full absolute top-0 px-8 user-container">
            <div className="flex flex-row w-full items-center">
                <div className="flex w-full justify-start">
                    <a href="/dashboard" className="logo">
                        {theme === 'light' ? <LogoWhite /> : <LogoBlack /> }
                    </a>
                </div>
                <div className="flex w-full justify-end">
                    <UserControls notifications={notifications}/>
                </div>
            </div>
        </div>
    )
}

export default TabBarWrapper;