import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardContext = React.createContext();

export function useDashboardContext() {
    return useContext(DashboardContext);
}

export function DashboardProvider({ children }) {
    const [selectedField, setSelectedField] = useState(null);

    const values = {
        selectedField,
        setSelectedField,
    } 

    return (
        <DashboardContext.Provider value={values}>
            {children}
        </DashboardContext.Provider>
    );
}