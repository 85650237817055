import React, { useState } from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';

const Timeline = () => {
    const [active, setActive] = useState(0);

    const handleButtonClick = (index) => {
        setActive(index);
    };

    const buttonLabels = ['1 Settimana', '1 mese', '3 mesi'];
    const indicatorWidth = 100 / buttonLabels.length;

    return (
        <div className="w-full bg-info rounded-full h-12 flex relative">
            {buttonLabels.map((label, index) => (
                <button
                    key={index}
                    className={`flex-1`}
                    onClick={() => handleButtonClick(index)}
                    style={{ position: 'relative', zIndex: 1 }}
                >
                    {label}
                </button>
            ))}
            <motion.div
                className="absolute w-full bg-selected h-full rounded-full"
                initial={false}
                animate={{
                    left: `${active * indicatorWidth}%`,
                    width: `${indicatorWidth}%`
                }}
                transition={{ type: "spring", stiffness: 200, damping: 20 }}
            />
        </div>
    );
};

export default Timeline;
