import React, { useState } from "react";

function HelperForm() {
    const [problemDescription, setProblemDescription] = useState('');
    const [lastRequest, setLastRequest] = useState('');

    const handleRequestSubmit = () => {
        setLastRequest(problemDescription); 
        setProblemDescription('');
    };

    return (
        <div className="bg-white rounded-2xl h-auto w-10/12 p-6 pt-10 ">
            <span className="text-xl font-bold px-4 ">Compila il Form</span>
            <div className="text-text-secondary font-medium px-4 py-2">
                <span className="font-medium text-m">Cerca di essere il più dettagliato possibile nella descrizione del problema</span>
            </div>

            <div className="pt-4 col-span-full">
                <textarea
                    id="ProblemEncountered"
                    name="ProblemEncountered"
                    rows={10}
                    className="px-4 block w-full rounded-2xl border-0 py-2 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 md:text-md md:leading-6"
                    value={problemDescription}
                    onChange={(e) => setProblemDescription(e.target.value)}
                    placeholder="Descrivi qui il tuo problema..."
                />
            </div>

            <div className="pt-5 flex justify-end">
                <button
                    type="button"
                    onClick={handleRequestSubmit}
                    className='mx-3 py-2 px-6 text-text-primary bg-accent hover:bg-accent-hover font-semibold inline-block rounded-full leading-normal'
                    disabled={!problemDescription.trim()}>
                    Invia Richiesta
                </button>
            </div>

            {lastRequest && (
                <div className="mt-4 p-4 bg-gray-100 rounded-md">
                    <p className="font-medium">Ultima Richiesta Inviata:</p>
                    <p>{lastRequest}</p>
                </div>
            )}
        </div>
    );
}

export default HelperForm;
