import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from 'framer-motion';
import { useNoteContext } from '../../contexts/NoteContext';

const TabBarButton = styled(motion.button).attrs({
    className: 'h-full flex-grow col tab-bar-button font-bold',
})`
    position: relative;
    flex: 1;
    z-index: 1;
`;

const Underline = styled(motion.div).attrs({
    className: 'underline bg-accent',
})`
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 0;
    z-index: 0;
`;

const containerVariants = {
    hidden: { y: 50, opacity: 0 },
    moveUp: { y: 0, opacity: 1 },
    moveDown: { y: 50, opacity: 0 }
};

const saveButtonVariants = {
    hidden: { y: 50, opacity: 0, visibility: "hidden" },
    visible: { y: 0, opacity: 1, visibility: "visible" },
    exit: { y: 50, opacity: 0, visibility: "hidden" }
};

const TabBar = ({ selected, setSelected }) => {
    const { isNoteEditing, submitForm } = useNoteContext();
    const numTabs = 4;
    const underlineWidth = 100 / numTabs;
    const [renderKey, setRenderKey] = useState(0);

    useEffect(() => {
        if (isNoteEditing) {
            const handleAnimation = setTimeout(() => {
                setRenderKey(prevKey => prevKey + 1);
            }, 100); // Timeout di 300ms per assicurarsi che l'animazione sia completata
            return () => clearTimeout(handleAnimation);
        }
    }, [isNoteEditing]);

    return (
        <div className="relative w-full h-24 text-center items-center">
            <AnimatePresence mode="wait">
                {!isNoteEditing ? (
                    <motion.div
                        key={`tabBar-${renderKey}`}
                        className="absolute w-full h-full flex row items-center"
                        initial="hidden"
                        animate="moveUp"
                        exit="moveDown"
                        variants={containerVariants}
                        transition={{ type: "spring", stiffness: 200, damping: 20, duration: 0.3 }}
                    >
                        <TabBarButton
                            key="tab1"
                            onClick={() => setSelected(0)}
                        >
                            Terreno
                        </TabBarButton>
                        <TabBarButton
                            key="tab2"
                            onClick={() => setSelected(1)}
                        >
                            <div className={`p-4 rounded-xl`}>
                                Aria
                            </div>
                        </TabBarButton>
                        <TabBarButton
                            key="tab3"
                            onClick={() => setSelected(2)}
                        >
                            Previsioni
                        </TabBarButton>
                        <TabBarButton
                            key="tab4"
                            onClick={() => setSelected(3)}
                        >
                            Note
                        </TabBarButton>
                        <Underline
                            initial={false}
                            animate={{
                                left: `${underlineWidth * selected}%`,
                                width: `${underlineWidth}%`,
                            }}
                            transition={{ type: "spring", stiffness: 200, damping: 20, duration: 0.1 }}
                        />
                    </motion.div>
                ) : (
                    <motion.div
                        key={`saveButtonContainer-${renderKey}`}
                        className="absolute w-full h-full flex items-center justify-center"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={saveButtonVariants}
                        transition={{ type: "spring", stiffness: 200, damping: 20, duration: 0.1 }}
                    >
                        <motion.button
                            className="bg-accent hover:bg-accent-hover text-black font-medium p-2 rounded w-full h-full text-lg"
                            onClick={submitForm}
                            variants={saveButtonVariants}
                        >
                            Salva
                        </motion.button>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default TabBar;
