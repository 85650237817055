import React from "react";
import Element from "../Forecasts/Element/Element";
import Timeline from "../Forecasts/Filters/Timeline";

const ForecastsSection = () => {
    return (
        <div className="w-full">
            <div>
                <div className="pb-2">
                    <Timeline />
                </div>
                <div className="flex justify-center pt-72 ">

                    <p className="text-center font-medium text-text-secondary "> Sezioni in addestramento</p>
                </div>
                {/*  <div className="w-full flex space-x-2 justify-end pb-2 ">
                    <Element element="Azoto" /> 

                    <Element element="Fosforo" />
                    <Element element="Potassio" />
                </div>
                <div className="w-full flex space-x-2 justify-end ">
                    <div></div>
                    <Element element="Ph" />
                    <Element element="Temperatura" />  
                </div>*/}
            </div>
        </div>
    )
}

export default ForecastsSection;