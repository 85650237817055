import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Icon = styled.div.attrs({
    className: `flex items-center rounded-full text-sm font-semibold justify-center`
})`
    width: 2.5rem; 
    height: 2.5rem;
    background-color: ${({ $bgColor }) => $bgColor};
`;

const SoilData = ({ data }) => {
    const [isImageLoading, setIsImageLoading] = useState(true);

    useEffect(() => {
        if (data?.ndvi_analysis?.ndvi_image) {
            setIsImageLoading(true);
        }
    }, [data?.ndvi_analysis?.ndvi_image]);

    const renderRow = (id, label, value, unit, range_min, range_max, warning, color) => (
        <div key={id} className="flex items-center p-1 border rounded-full mb-2 justify-between">
            {/* Icona */}
            <div className="flex w-40 items-center space-x-4">
                <Icon $bgColor={color}>{id}</Icon>
                <span className="font-medium value-name-label">{label}</span>
            </div>
            {/* Dettagli */}
            <div className="text-gray-500 text-center">
                <span className='value-range-label'>{range_min} - {range_max}</span>
            </div>
            {/* Valore Misurato */}
            <div className="flex">
                <span>{value} {unit}</span>
            </div>
            {/* Avvertimento */}
            <div className='text-center w-12 px-4'>
                {warning && <FontAwesomeIcon icon={faWarning} className='warning-icon' />}
            </div>
        </div>
    );

    const soilParameters = [
        { id: "N", label: "Azoto", parameter: data?.nitrogen, color: "#E7BEBE" },
        { id: "P", label: "Fosforo", parameter: data?.phosphorus, color: "#F5D8AF" },
        { id: "K", label: "Potassio", parameter: data?.potassium, color: "#BBE6C3" },
        { id: "C°", label: "Temperatura", parameter: data?.soil_temperature, color: "#F4C2F1" },
        { id: "RH", label: "Umidità", parameter: data?.soil_moisture, color: "#BBBED6" },
    ];

    return (
        <div className="py-5">
            {data?.ndvi_analysis && (
                <div className="border rounded-lg h-72 overflow-hidden">
                    <div className="bg-[#2c3e50] p-2 flex justify-end pe-4">
                        <div className="text-white flex items-center">
                            <Tippy content={
                                <div className="text-left">
                                    <p><strong>NDVI (Normalized Difference Vegetation Index)</strong></p>
                                    <p><span style={{ color: '#00FF00' }}>Verde</span>: Vegetazione molto sana (0.66 - 1.0)</p>
                                    <p><span style={{ color: '#FFFF00' }}>Giallo</span>: Vegetazione sana (0.33 - 0.65)</p>
                                    <p><span style={{ color: '#FFA500' }}>Arancione</span>: Vegetazione stressata (0.0 - 0.32)</p>
                                    <p><span style={{ color: '#FF0000' }}>Rosso</span>: Vegetazione morta o assente (-1.0 - 0.0)</p>
                                    <p><span style={{ color: '#808080' }}>Grigio</span>: Terreno nudo o neve</p>
                                </div>
                            }>
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    className="me-2 text-white cursor-pointer "
                                />
                            </Tippy>
                            <p className="font-semibold me-2">Valore NDVI:</p>
                            <p className="font-regular">{data.ndvi_analysis.ndvi_value}</p>
                        </div>
                    </div>

                    <img
                        src={data.ndvi_analysis.ndvi_image}
                        alt="NDVI Analysis"
                    />
                </div>
            )}
            <div className="pt-7">
                {soilParameters.map(
                    ({ id, label, parameter, color }) =>
                        parameter && renderRow(id, label, parameter.value, parameter.unit, parameter.range_min, parameter.range_max, parameter.warning, color)
                )}
            </div>
        </div>
    );
};

export default SoilData;
