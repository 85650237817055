// Media Mobile Esponenziale (EMA)
// si basa su:
// ndvi, io ho una lista con diversi valori:
// da 0.66 a 1 -> Pianta in salute
// da 0.33 a 0.66 -> Pianta sana
// da 0 a 0.33-> Pianta morente
// da -1 a 0 -> Pianta morta o oggetto inanimato 

export const calculateEMA = (data, windowSize) => {
    const k = 2 / (windowSize + 1);
    let emaArray = [data[0].ndvi];

    for (let i = 1; i < data.length; i++) {
        const ema = data[i].ndvi * k + emaArray[i - 1] * (1 - k);
        emaArray.push(ema);
    }

    return emaArray;
};

export const determineTrend = (emaArray) => {
    if (emaArray.length < 2) return { trend: 'Stabile', change: 0 };
    const lastEMA = emaArray[emaArray.length - 1];
    const secondLastEMA = emaArray[emaArray.length - 2];
    const diff = lastEMA - secondLastEMA;
    const percentageChange = (diff / secondLastEMA) * 100;

    if (Math.abs(percentageChange) < 0.5) { 
        return { trend: 'Stabile', change: percentageChange };
    } else if (diff > 0) {
        return { trend: 'Incremento', change: percentageChange };
    } else {
        return { trend: 'Decremento', change: percentageChange };
    }
};
