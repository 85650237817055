import React from "react";
import SoilAnalysis from "../Soil/SoilAnalysis";
import GraphCard from "../Soil/GraphCard";

const SoilSection = () => {

    return (
        <div className="w-full space-y-6">
            <SoilAnalysis />
            <GraphCard />
        </div>
    )
}

export default SoilSection;