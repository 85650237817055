import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./RequestHarvest.css";  // You can use the same CSS or create a new one if needed

const RequestInsurance = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [message, setMessage] = useState(''); 

    const saveInsuranceRequest = async (data) => {
        try {
            const response = await axios.post('https://93h5z5d4b7.execute-api.eu-central-1.amazonaws.com/dev/save-request-insurance', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setMessage(response.data.message);
            return response.data;
        } catch (error) {
            console.error("Error saving insurance request:", error);
            throw error;
        }
    };

    const formik = useFormik({
        initialValues: {
            vatNumber: '',
            email: '',
            phoneNumber: '',
            privacy: false,
            marketing: false,
        },
        validationSchema: Yup.object({
            vatNumber: Yup.string().matches(/^[A-Z0-9]+$/, "Invalid VAT Number").required("Required"),
            email: Yup.string().email("Invalid email address").required("Required"),
            phoneNumber: Yup.string().matches(/^[0-9]+$/, "Invalid phone number").required("Required"),
            privacy: Yup.boolean().oneOf([true], "Privacy policy acceptance is required"),
            marketing: Yup.boolean().oneOf([true], "Marketing consent is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await saveInsuranceRequest(values);
                setIsSubmitted(true);
                resetForm();
            } catch (error) {
                console.error('Error submitting the form:', error);
            }
        },
    });

    const handleReset = () => {
        formik.resetForm();
        setIsSubmitted(false);
    };

    return (
        <div className="backgroundImage-insurance">
            <div className="flex items-center justify-center h-full overflow-auto">
                <div className="px-5 md:px-12 pt-32 md:pt-10 glass md:h-fit card-dimensions-register rounded-none md:rounded-3xl overflow-hidden text-white">
                    {isSubmitted ? (
                        <div className="text-center min-h-96 flex items-center flex-col justify-center">
                            <p className="text-2xl md:text-4xl font-bold">{message}</p>
                            <button
                                onClick={handleReset}
                                className="mt-8 py-2 px-6 font-semibold inline-block rounded-full leading-normal bg-accent hover:bg-accent-hover text-text-primary"
                            >
                                Torna Indietro
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className="justify-start text-4xl md:text-5xl title-register">
                                <span className="font-regular">Richiedi </span>
                                <span className="font-bold">Harvest<i>finance</i></span>
                            </div>
                            <p className="mt-4 text-xl md:text-2xl font-light text-start">Compila il form con i tuoi dati</p>
                            <form onSubmit={formik.handleSubmit} className="my-8">
                                <div className="flex flex-col gap-3 ">
                                    <input
                                        type="text"
                                        placeholder="P.IVA"
                                        name="vatNumber"
                                        value={formik.values.vatNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`input-placeholder bg-white text-black px-6 py-2 rounded-full justify-start w-full ${formik.touched.vatNumber && formik.errors.vatNumber ? 'border-red-500' : ''}`}
                                    />
                                    <input
                                        type="email"
                                        placeholder="E-mail"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`input-placeholder bg-white text-black px-6 py-2 rounded-full justify-start w-full ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Cellulare"
                                        name="phoneNumber"
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`input-placeholder bg-white text-black px-6 py-2 rounded-full justify-start w-full ${formik.touched.phoneNumber && formik.errors.phoneNumber ? 'border-red-500' : ''}`}
                                    />
                                </div>
                                <div className="flex justify-between items-center mt-8">
                                    <div className="flex flex-col">
                                        <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                name="privacy"
                                                checked={formik.values.privacy}
                                                onChange={formik.handleChange}
                                                className="form-checkbox text-white border-white"
                                            />
                                            <span className="ml-2">Accetto i termini sulla privacy <span className="text-red-500">*</span></span>
                                        </label>
                                        {formik.touched.privacy && formik.errors.privacy ? (
                                            <div className="error text-red-500">{formik.errors.privacy}</div>
                                        ) : null}
                                        <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                name="marketing"
                                                checked={formik.values.marketing}
                                                onChange={formik.handleChange}
                                                className="form-checkbox text-white border-white"
                                            />
                                            <span className="ml-2">Consenso Marketing <span className="text-red-500">*</span></span>
                                        </label>
                                        {formik.touched.marketing && formik.errors.marketing ? (
                                            <div className="error text-red-500">{formik.errors.marketing}</div>
                                        ) : null}
                                    </div>
                                    <div className="hidden md:flex justify-end align-end p-0 m-0">
                                        <button
                                            type="submit"
                                            className={` py-2 px-6 font-semibold inline-block rounded-full leading-normal ${formik.isValid ? 'bg-accent hover:bg-accent-hover text-text-primary' : 'bg-gray-300 text-white'}`}
                                        >
                                            Invia Richiesta
                                        </button>
                                    </div>
                                </div>
                                <div className="flex md:hidden justify-center py-8">
                                    <button
                                        type="submit"
                                        className={`py-2 px-8 font-semibold inline-block rounded-full leading-normal ${formik.isValid ? 'bg-accent hover:bg-accent-hover text-text-primary' : 'bg-gray-300 text-white'}`}
                                    >
                                        Invia Richiesta
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RequestInsurance;
