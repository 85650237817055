import React, { useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';

const UploadFileNote = ({ images, setFieldValue }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleFilesChange = useCallback((event) => {
    const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    const files = Array.from(event.target.files);

    const newImages = files.map(file => {
      if (supportedFormats.includes(file.type)) {
        return {
          file,
          url: URL.createObjectURL(file)
        };
      } else {
        toast.error(`Il file ${file.name} non è supportato.\nUsa solo estensioni JPEG, JPG, PNG.`);
        return null;
      }
    }).filter(Boolean);

    setFieldValue('images', [...images, ...newImages]);
    event.target.value = ''; // Reset the input file for new uploads
  }, [images, setFieldValue]);

  const removeImage = useCallback((index) => {
    const newImages = images.filter((_, i) => i !== index);
    setFieldValue('images', newImages);
  }, [images, setFieldValue]);

  return (
    <div>
      <Toaster position="top-center" />
      <p className='font-bold pt-5 mb-2'>Aggiungi una o più Foto:</p>
      <div className={`grid ${images.length === 0 ? 'place-items-center' : 'grid-cols-5 gap-2'}`}>
        {images.map((image, index) => (
          <div key={index} className="relative group w-full" style={{ paddingBottom: '120%' }}>
            <img src={image.url} alt="Uploaded" className='absolute top-0 left-0 w-full h-full object-cover border rounded-lg' />
            <div className="absolute inset-0 rounded-lg bg-black bg-opacity-0 group-hover:bg-opacity-25 transition-opacity duration-300"></div>
            <button type='button' onClick={() => removeImage(index)} className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100">
              <FontAwesomeIcon icon={faTrash} className='text-white' />
            </button>
          </div>
        ))}
        <div className="relative group w-full rounded-lg border border-gray-300" style={{ paddingBottom: images.length === 0 ? '23%' : '120%' }}>
          <button type='button' onClick={handleButtonClick} className="absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer focus:outline-none">
            <FontAwesomeIcon icon={faPlusCircle} className='text-5xl text-gray-300 hover:text-gray-400' />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFilesChange}
            style={{ display: 'none' }}
            multiple
          />
        </div>
      </div>
    </div>
  );
};

export default UploadFileNote;
