import React from "react";
import CurrentWeatherCard from "../WeatherCards/CurrentWeatherCard";
import ForecastCard from "../WeatherCards/ForecastCard";
import SevereWeatherCard from "../WeatherCards/SevereWeatherCard";

const WeatherSection = () => {
    return (
        <div className="w-full">
            <div className="flex flex-row w-full space gap-2 pb-2">
                <CurrentWeatherCard
                    type={"temperature"}
                />
                <CurrentWeatherCard
                    type={"rainfall"}
                />
            </div>
            <div className="flex flex-row w-full space gap-2">
                <CurrentWeatherCard
                    type={"humidity"}
                />
                <CurrentWeatherCard
                    type={"wind"}
                />
            </div>

            <div className="row w-full mt-2">
                <ForecastCard />
            </div>

            <div className="row w-full mt-2 h-52">
                <SevereWeatherCard />
            </div>

        </div>
    )
}

export default WeatherSection;