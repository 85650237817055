
import { Link } from "react-router-dom";
import PersonalInformationForm from "../../components/GeneralSettings/PersonalInformation";
import { Breadcrumb } from "./Breadcrumb";

const PersonalInfo = () => {

    return (
        <div className="w-full p-24" style={{ height: '100vh' }}>
            <div className="h-full flex items-center justify-between flex-col">
                <div className="flex flex-col justify-start w-full">
                    <Breadcrumb title="Impostazioni" route="/settings" backroute="Impostazioni" currentPage="Informazioni personali" />
                </div>

                <div className="flex justify-center">
                    <PersonalInformationForm />
                </div>

                <div></div>
            </div>
        </div>
    )
}

export default PersonalInfo;
