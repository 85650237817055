import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';  // For validation
import API from "../../services/api";
import Loader from "../Loader/Loader";
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTractor, faUser } from "@fortawesome/free-solid-svg-icons";

// Funzione per gestire l'invio del modulo dei dettagli aziendali
const requestSent = (data) => {
    console.log('Modifiche:', data);
    console.log('Modifiche salvate');
};

// Validation schema using Yup for form validation
const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('Il nome della compagnia è richiesto'),
    location: Yup.string().required('La località è richiesta'),
    email: Yup.string().email('Email non valida').required('Email è richiesta'),
    phone: Yup.string().required('Il numero di telefono è richiesto'),
    vat_number: Yup.string().required('La Partita IVA è richiesta'),
    companyID: Yup.string().required('L\'ID della compagnia è richiesto'),
    wallet_ID: Yup.string().required('L\'ID del portafoglio è richiesto')
});

const PersonalInformationForm = () => {
    const [initialData, setInitialData] = useState({
        location: '',
        company_name: '',
        companyID: '',
        email: '',
        wallet_ID: '',
        phone: '',
        vat_number: ''
    });
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await API().get('/company-info');
                setInitialData(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-96">
                <Loader />
            </div>
        );
    }

    return (
        <div className="bg-white rounded-2xl h-auto w-10/12 p-6">
            <div className="flex justify-center items-center mb-6">
                <span className="text-xl font-bold"><FontAwesomeIcon icon={faUser} className="me-2"/> Informazioni Personali</span>
            </div>
            <div className="flex justify-center items-center w-full text-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-lg">
                    <div className="flex flex-col items-center">
                        <label className="block text-md font-medium leading-6 text-gray-900">Nome</label>
                        <p className="block w-full py-1.5 text-gray-900">{user.given_name}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <label className="block text-md font-medium leading-6 text-gray-900">Cognome</label>
                        <p className="block w-full py-1.5 text-gray-900">{user.family_name}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <label className="block text-md font-medium leading-6 text-gray-900">Email</label>
                        <p className="block w-full py-1.5 text-gray-900">{user.email}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <label className="block text-md font-medium leading-6 text-gray-900">Nome utente</label>
                        <p className="block w-full py-1.5 text-gray-900">{user.username}</p>
                    </div>
                </div>
            </div>
            <div className="mt-10 bg-slate-100 shadow-lg p-6 rounded-2xl">
                <div className="flex justify-center items-center mb-6">
                    <span className="text-xl font-bold"><FontAwesomeIcon icon={faTractor} className="me-2"/> Dettagli Azienda</span>
                </div>
                <Formik
                    initialValues={initialData}
                    validationSchema={validationSchema}
                    enableReinitialize={true} 
                    onSubmit={(values, { setSubmitting }) => {
                        requestSent(values);  // Riutilizzo della funzione requestSent per i dettagli aziendali
                        setSubmitting(false);
                    }}
                >
                    {formik => (
                        <Form className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            {/* Input fields with validation feedback */}
                            <div className="md:col-span-2">
                                <label htmlFor="company_name" className="px-4 pb-1 block text-md font-medium leading-6 text-gray-900">Azienda</label>
                                <input
                                    {...formik.getFieldProps('company_name')}
                                    type="text"
                                    id="company_name"
                                    className="px-4 block w-full rounded-2xl border-0 py-1.5 text-gray-900 ring-1 ring-text-secondary placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 md:text-md md:leading-6"
                                    disabled
                                />
                                {formik.touched.company_name && formik.errors.company_name ? <div className="text-red-600">{formik.errors.company_name}</div> : null}
                            </div>
                            <div className="md:col-span-2">
                                <label htmlFor="location" className="px-4 pb-1 block text-md font-medium leading-6 text-gray-900">Luogo</label>
                                <input {...formik.getFieldProps('location')} type="text" id="location" className="px-4 block w-full rounded-2xl border-0 py-1.5 text-gray-900 ring-1 ring-text-secondary placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 md:text-md md:leading-6" disabled/>
                                {formik.touched.location && formik.errors.location ? <div className="text-red-600">{formik.errors.location}</div> : null}
                            </div>
                            <div className="md:col-span-2">
                                <label htmlFor="email" className="px-4 pb-1 block text-md font-medium leading-6 text-gray-900">Email</label>
                                <input
                                    {...formik.getFieldProps('email')}
                                    type="email"
                                    id="email"
                                    className="px-4 block w-full rounded-2xl border-0 py-1.5 text-gray-900 ring-1 ring-text-secondary placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 md:text-md md:leading-6"
                                    disabled
                                />
                                {formik.touched.email && formik.errors.email ? <div className="text-red-600">{formik.errors.email}</div> : null}
                            </div>
                            <div className="md:col-span-1">
                                <label htmlFor="phone" className="px-4 pb-1 block text-md font-medium leading-6 text-gray-900">Cellulare</label>
                                <input
                                    {...formik.getFieldProps('phone')}
                                    type="text"
                                    id="phone"
                                    className="px-4 block w-full rounded-2xl border-0 py-1.5 text-gray-900 ring-1 ring-text-secondary placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 md:text-md md:leading-6"
                                    disabled
                                />
                                {formik.touched.phone && formik.errors.phone ? <div className="text-red-600">{formik.errors.phone}</div> : null}
                            </div>
                            <div className="md:col-span-1">
                                <label htmlFor="vat_number" className="px-4 pb-1 block text-md font-medium leading-6 text-gray-900">P. IVA</label>
                                <input
                                    {...formik.getFieldProps('vat_number')}
                                    type="text"
                                    id="vat_number"
                                    className="px-4 block w-full rounded-2xl border-0 py-1.5 text-gray-900 ring-1 ring-text-secondary placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 md:text-md md:leading-6"
                                    disabled
                                />
                                {formik.touched.vat_number && formik.errors.vat_number ? <div className="text-red-600">{formik.errors.vat_number}</div> : null}
                            </div>
                            {/* Add other fields if necessary */}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default PersonalInformationForm;
