import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFile, faCircleInfo, faBook} from '@fortawesome/free-solid-svg-icons';

function SettingsCardHeader({ type, showIcon = true, title }) {
    let Icon;
    let description = '';

    const setCard = () => {
        switch (type) {
            case 'personalInformations':
                description="Informazioni personali"
                return <FontAwesomeIcon icon={faUser} />
            case 'billing':
                description="Documenti, Fatture, Pagamenti"
                return <FontAwesomeIcon icon={faFile} />
            case 'archive':
                description="Note"
                return <FontAwesomeIcon icon={faBook} />
            case 'helper':
                description="Form di richiesta assistenza";
                return <FontAwesomeIcon icon={faCircleInfo} />
            default:
                return ""
        }
    }

    return (
        <div className="flex flex-col h-32 w-64 bg-white hover:bg-stone-50 rounded-2xl justify-center items-start text-left p-5">
            <div>
                {setCard()}
            </div>
            <span className="text-text-primary text-1xl pt-2 font-bold ">{title}</span>
            <span className="text-text-secondary text-m font-regular ">{description}</span>
        </div>
    );
}
export default SettingsCardHeader;