import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsCardHeader from "../../components/GeneralSettings/SettingsCardHeader";
import { useAuth } from "../../contexts/AuthContext";

function Settings() {
    const navigate = useNavigate();
    const { setTheme, connectWallet, accountAddress, user } = useAuth();

    useEffect(() => {
        setTheme("dark");
    }, [setTheme])

    // Funzione per navigare basata sul tipo
    const handleNavigation = (type) => {
        switch (type) {
            case "personalInformations":
                navigate("/personal-info");
                break;
            case "billing":
                navigate("/billing-info");
                break;
            case "archive":
                navigate("/archive");
                break;
            case "helper":
                navigate("/help");
                break;
            default:
                break;
        }
    }

    return (
        <div className="w-full p-24" style={{ height: '100vh' }}>
            <div className="h-full flex items-center justify-between flex-col">

                <div className="flex flex-col justify-start w-full">

                    <span className="font-semibolt text-2xl font-bold pb-2">Impostazioni</span>
                    <div className="flex flex-row text-text-secondary font-medium gap-3">
                        <span className="font-medium text-xl">Ciao, {user.given_name} {user.family_name}!</span>
                    </div>
                </div>

                <div className=" w-full h-full flex flex-col items-center justify-center">
                    <div className="flex  justify-center flex-row gap-4">
                        <button onClick={() => handleNavigation("personalInformations")}>
                            <SettingsCardHeader type="personalInformations" title={"Informazioni Personali"} />
                        </button>
                        <button onClick={() => handleNavigation("billing")}>
                            <SettingsCardHeader type="billing" title={"Fatturazione"} />
                        </button>
                    </div>

                    <div className="flex justify-center w-full pt-4 gap-4">
                        <button onClick={() => handleNavigation("helper")}>
                            <SettingsCardHeader type="helper" title={"Assistenza"} />
                        </button>
                    </div>
                </div>
                <div className="w-full flex justify-end items-center">
                    {!accountAddress ? (
                        <button onClick={connectWallet} className="bg-accent text-black px-4 py-2 rounded">
                            Connetti Pera Wallet
                        </button>
                    ) : (
                        <>
                            <div className="flex flex-col">
                                <span className="text-green-500 font-bold">Wallet Connesso:</span>
                                <span className="">{accountAddress}</span>
                            </div>
                            <div className="w-full flex justify-end">
                                <button className="bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded ms-8"> Disconnect Wallet</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>

    )
}

export default Settings;