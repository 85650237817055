import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import SidebarNote from './SidebarNote';
import SidebarAnalysis from './SidebarAnalysis';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import Connect from '../../services/api';
import PulseLoader from 'react-spinners/PulseLoader';

const SidebarTabs = ({ isOpen, onClose, event, updateCalendarSize }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('note');
  const [analysisData, setAnalysisData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previousEvent, setPreviousEvent] = useState(null);

  const retrieveArchiveData = async (event) => {
    if (!event) return;

    setIsLoading(true);
    const formattedDate = format(new Date(event.timestamp), 'yyyy-MM-dd');
    const API = Connect(undefined, undefined, { field_id: event.id });

    try {
      const response = await API.get(`/archive-item?date=${formattedDate}`);
      setAnalysisData(response.data); 
    } catch (error) {
      console.error('Error retrieving archive data:', error);
      setAnalysisData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSidebarOpen(false); 
    } else {
      // Controllo se l'evento è diverso dall'evento precedente
      if (!previousEvent || event.id !== previousEvent.id || event.timestamp.toString() !== previousEvent.timestamp.toString()) {
        retrieveArchiveData(event);
        setPreviousEvent(event);
      } else {
        setSidebarOpen(true); // Apri la sidebar senza caricare se è lo stesso evento
      }
    }
  }, [isOpen, event]);

  const handleClose = () => {
    onClose();
    setTimeout(updateCalendarSize, 300); // Delay to match the sidebar closing transition
  };

  const formattedDate = event && event.timestamp ? format(new Date(event.timestamp), 'dd MMMM yyyy', { locale: it }) : 'Data non disponibile';

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: '32rem' }}
          exit={{ width: 0 }}
          transition={{ duration: 0.5 }}
          style={{ position: 'absolute', right: 0, top: 0, bottom: 0, background: 'white', zIndex: 1000, paddingTop: '20px', paddingBottom: '20px', paddingRight: '20px' }}
          onAnimationComplete={(definition) => {
            if (definition.width === '32rem') {
              setSidebarOpen(true);
            }
          }}
        >
          <button className="close-button" onClick={handleClose} style={{ position: 'absolute', top: 20, right: 20, background: 'none', border: 'none', fontSize: 20, cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faX} />
          </button>
          {sidebarOpen && (
            <div className="">
              <div className="text-right text-lg text-gray-400 px-9 mt-1">
                {formattedDate}
              </div>
              <div className='text-3xl font-bold'>
                {event.fieldName}
              </div>
              {isLoading ? (
                <div className="flex items-center justify-center h-full mt-96">
                  <PulseLoader color="rgb(44, 62, 80)" loading={isLoading} />
                </div>
              ) : (
                <Tabs value={activeTab} >
                  <TabsHeader
                    className="rounded-none bg-transparent mt-7"
                    indicatorProps={{
                      className: "bg-transparent border-b-2 border-accent shadow-none rounded-none z--10",
                    }}
                  >
                    <Tab
                      value="note"
                      onClick={() => setActiveTab('note')}
                      className={activeTab === 'note' ? 'text-gray-900 font-bold' : ''}
                    >
                      Note
                    </Tab>
                    <Tab
                      value="analysis"
                      onClick={() => setActiveTab('analysis')}
                      className={activeTab === 'analysis' ? 'text-gray-900 font-bold' : ''}
                    >
                      Analisi
                    </Tab>
                  </TabsHeader>
                  {analysisData && (
                    <TabsBody>
                      <TabPanel value="note">
                        <SidebarNote data={analysisData.notes[0]} />
                      </TabPanel>
                      <TabPanel value="analysis">
                        <SidebarAnalysis event={analysisData} />
                      </TabPanel>
                    </TabsBody>
                  )}
                </Tabs>
              )}
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SidebarTabs;
