import React, { useState, useRef } from 'react';  
import { Input, InputAdornment, IconButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "../../contexts/AuthContext";
import { Link } from 'react-router-dom';  
import "./CustomerArea.css";

const validationSchema = Yup.object({
    email: Yup.string().email('Indirizzo email non valido').required('L\'email è richiesta'),
    password: Yup.string().required('La password è richiesta'),
});

const CustomerArea = () => {
    const { login } = useAuth();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            showPassword: false
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const response = await login(values.email, values.password);
            } catch (error) {
                console.error('Login failed:', error);
            }
        },
    });

    const passwordInputRef = useRef(null); // Crea il ref

    const togglePasswordVisibility = () => {
        formik.setFieldValue('showPassword', !formik.values.showPassword, false);
        setTimeout(() => {
            if (passwordInputRef.current) {
                passwordInputRef.current.focus(); // Rimetti il focus sull'input della password
                const length = formik.values.password.length;
                passwordInputRef.current.setSelectionRange(length, length); // Imposta la posizione del cursore alla fine del testo
            }
        }, 0);
    };

    return (
        <div className="backgroundImage-customer">
            <div className="flex items-center justify-center h-screen overflow-auto">
                <form onSubmit={formik.handleSubmit} className="px-4 md:px-12 py-5 glass card-dimensions rounded-3xl overflow-hidden text-white">
                    <div className="py-8 text-center gap-4">
                        <span className="text-5xl small-title font-light">Entra in </span>
                        <span className="text-5xl small-title font-regular">Harvest</span>
                    </div>
                    <div className="pt-9">
                        <Input
                            disableUnderline
                            placeholder="E-mail"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            className={`input-placeholder bg-white px-6 py-2 rounded-full justify-start w-full ${formik.touched.email && formik.errors.email ? 'error' : ''
                                }`}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="error-message">{formik.errors.email}</div>
                        ) : null}
                        <div className="pt-5">
                            <Input
                                placeholder="Password"
                                disableUnderline
                                name="password"
                                type={formik.values.showPassword ? 'text' : 'password'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                className={`input-placeholder bg-white px-6 py-2 rounded-full justify-start w-full ${formik.touched.password && formik.errors.password ? 'error' : ''
                                    }`}
                                endAdornment={
                                    <InputAdornment className="p-3" position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            onMouseDown={(event) => event.preventDefault()} // Previene la perdita del focus
                                            edge="end"
                                        >
                                            {formik.values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputRef={passwordInputRef}  // Usa il ref qui
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="error-message">{formik.errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex justify-start mt-4 ">
                        <Link to="/forgot-password" className="justify-end text-white hover:underline">
                            <span> Password dimenticata? </span>
                        </Link>
                    </div>
                    <div className="flex justify-center  mt-8">
                        <button type="submit" className="mx-3 py-2 px-7 text-black bg-accent hover:bg-accent-hover font-semibold rounded-full leading-normal">
                            Accedi
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CustomerArea;
