
import React, { useState } from "react";
import './SetPassword.css';
import { Input, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth } from "../../contexts/AuthContext";




const SetPassword = () => {

    const {login, onNewPasswordRequired} = useAuth();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const requestSent = () => { 
        if (newPassword === confirmPassword) {
            onNewPasswordRequired(newPassword).then(response =>{
                if (response) {
                    console.log(response);
                }
            });
        }
     };

    return (
        <div className="backgroundImage-SetPassword ">

            <div className="flex items-center justify-center h-screen overflow-auto ">
                <div className="px-12 py-5 glass card-dimensions rounded-3xl overflow-hidden text-black">
                    <div className="pt-8 justify-center flex gap-4 ">
                        <p className="text-5xl font-light"> Entra in </p>
                        <p className="text-5xl font-regular"> Harvest</p>
                    </div>
                    <div className="pt-9">
                        <p className="text-m font-regular text-center">Imposta la tua nuova password</p>
                    </div>
                    <div className="pt-5 ">

                        <Input
                            placeholder="Nuova Password"
                            disableUnderline
                            id="outlined-adornment-new-password"
                            type={showNewPassword ? 'text' : 'password'}
                            onChange={(e) => setNewPassword(e.target.value)}
                            sx={{
                                width: '100%',
                                borderRadius: '50px',
                                backgroundColor: 'white',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    borderColor: 'none',
                                }
                            }}
                            className="input-placeholder bg-white px-6 py-2 rounded-full justify-start w-full"
                            endAdornment={
                                <InputAdornment className="p-3" position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />

                        <div className="pt-5">

                            <Input
                                placeholder="Conferma Password"
                                disableUnderline
                                id="outlined-adornment-confirm-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                sx={{
                                    width: '100%',
                                    borderRadius: '50px',
                                    backgroundColor: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                        borderColor: 'none',
                                    }
                                }}
                                className="input-placeholder bg-white px-6 py-2 rounded-full justify-start w-full"
                                endAdornment={
                                    <InputAdornment className="p-3" position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>

                    </div>

                    <div className="flex justify-center pt-8">
                        <button
                            type="button"
                            onClick={requestSent}
                            className={`mx-3 py-2 px-7 text-text-primary bg-accent font-semibold inline-block rounded-full leading-normal} `}>
                            Accedi
                        </button>
                    </div>
                </div>
            </div>
        </div>




    );

}
export default SetPassword;
