import React, { useState, useRef } from 'react';
import { Input, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "../../contexts/AuthContext";
import "./CustomerArea.css";

const validationSchema = Yup.object({
    email: Yup.string().email('Indirizzo email non valido').required('L\'email è richiesta'),
    verificationCode: Yup.string().required('Il codice di verifica è richiesto'),
    password: Yup.string().required('La password è richiesta'),
});

const ForgotPassword = () => {
    const { forgotPassword, onNewPassword } = useAuth();
    const [showVerificationForm, setShowVerificationForm] = useState(false);
    const passwordInputRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Indirizzo email non valido').required('L\'email è richiesta'),
        }),
        onSubmit: async (values) => {
            try {
                const response = await forgotPassword(values.email, onNewPasswordRequired);
            } catch (error) {
                console.error('Request failed:', error);
            }
        },
    });
    
    const onNewPasswordRequired = () => {
        setShowVerificationForm(true);
    }

    const formikForgotPw = useFormik({
        initialValues: {
            verificationCode: '',
            password: '',
            showPassword: false
        },
        validationSchema: Yup.object({
            verificationCode: Yup.string().required('Il codice di verifica è richiesto'),
            password: Yup.string().required('La password è richiesta'),
        }),
        onSubmit: async (values) => {
            console.log(values);
            try {
                const response = await onNewPassword(values.verificationCode, values.password);
                console.log(response);
            } catch (error) {
                console.error('Reset password failed:', error);
            }
        },
    });

    const togglePasswordVisibility = () => {
        formikForgotPw.setFieldValue('showPassword', !formikForgotPw.values.showPassword, false);
        setTimeout(() => {
            if (passwordInputRef.current) {
                passwordInputRef.current.focus();
                const length = formikForgotPw.values.password.length;
                passwordInputRef.current.setSelectionRange(length, length);
            }
        }, 0);
    };

    return (
        <div className="backgroundImage-customer">
            <div className="flex items-center justify-center h-screen overflow-auto">
                {!showVerificationForm ?
                    <form onSubmit={formik.handleSubmit} className="px-12 py-5 glass card-dimensions rounded-3xl overflow-hidden text-white">
                        <div>
                            <div className="py-8 justify-center flex gap-4">
                                <p className="text-5xl font-light">Password Dimenticata?</p>
                            </div>
                            <div className="pt-10 ">
                                <Input
                                    disableUnderline
                                    placeholder="E-mail"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                    className={`input-placeholder bg-white px-6 py-2 rounded-full justify-start w-full ${formik.touched.email && formik.errors.email ? 'error' : ''}`}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="error-message">{formik.errors.email}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex justify-center pt-11">
                            <button type="submit" className="mx-3 py-2 px-7 text-black bg-accent font-semibold rounded-full leading-normal">
                                Richiedi
                            </button>
                        </div>
                    </form>
                    :
                    <form onSubmit={formikForgotPw.handleSubmit} className="px-12 py-5 glass card-dimensions rounded-3xl overflow-hidden text-white">
                        <div>
                            <div className="py-8 justify-center flex gap-4">
                                <p className="text-5xl font-light">Password Dimenticata?</p>
                            </div>
                            <div className="pt-16">
                                <Input
                                    disableUnderline
                                    placeholder="Inserisci il codice di verifica"
                                    name="verificationCode"
                                    autoComplete='verification-code'
                                    type="text"
                                    onChange={formikForgotPw.handleChange}
                                    value={formikForgotPw.values.verificationCode}
                                    onBlur={formikForgotPw.handleBlur}
                                    className={`input-placeholder bg-white px-6 py-2 rounded-full justify-start w-full ${formikForgotPw.touched.verificationCode && formikForgotPw.errors.verificationCode ? 'error' : ''}`}
                                />
                                {formikForgotPw.touched.verificationCode && formikForgotPw.errors.verificationCode ? (
                                    <div className="error-message">{formikForgotPw.errors.verificationCode}</div>
                                ) : null}
                            </div>
                            <div className="pt-5">
                                <Input
                                    placeholder="Nuova Password"
                                    disableUnderline
                                    autoComplete='new-password'
                                    name="password"
                                    type={formikForgotPw.values.showPassword ? 'text' : 'password'}
                                    onChange={formikForgotPw.handleChange}
                                    onBlur={formikForgotPw.handleBlur}
                                    value={formikForgotPw.values.password}
                                    className={`input-placeholder bg-white px-6 py-2 rounded-full justify-start w-full ${formikForgotPw.touched.password && formikForgotPw.errors.password ? 'error' : ''}`}
                                    endAdornment={
                                        <InputAdornment className="p-3" position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                            >
                                                {formikForgotPw.values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputRef={passwordInputRef}
                                />
                                {formikForgotPw.touched.password && formikForgotPw.errors.password ? (
                                    <div className="error-message">{formikForgotPw.errors.password}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex justify-center pt-11">
                            <button type="submit" className="mx-3 py-2 px-7 text-black bg-accent font-semibold rounded-full leading-normal">
                                Reimposta
                            </button>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
};

export default ForgotPassword;
