import React, { useEffect, useState } from "react";
import TimeSelector from "./TimeSelector";
import AirData from "./AirData";
import SoilData from "./SoilData";

const SidebarAnalysis = ({ event }) => {
  const [selectedTime, setSelectedTime] = useState('morning');
  const [data, setData] = useState(null);

  useEffect(() => {
    if (event) {
      const { air = {}, soil = {} } = event;
      let time = 'morning';

      if (!air.morning?.length && !air.afternoon?.length && !air.evening?.length) {
        // No data available
        setData(null);
      } else {
        if (air.morning?.length) {
          time = 'morning';
        } else if (air.afternoon?.length) {
          time = 'afternoon';
        } else if (air.evening?.length) {
          time = 'evening';
        }
        setSelectedTime(time);
        const airData = air[time]?.[0] || {};
        const soilData = soil[time]?.[0] || {};
        setData({
          air: airData,
          soil: soilData,
        });
      }
    }
  }, [event]);

  const handleTimeChange = (time) => {
    const airData = event.air ? event.air[time] : [];
    const soilData = event.soil ? event.soil[time] : [];
    setSelectedTime(time);
    setData({
      air: airData?.[0] || {},
      soil: soilData?.[0] || {},
    });
  };

  if (!event) {
    return null;
  }

  return (
    <div className="flex flex-col h-full w-full pt-4">
      <TimeSelector selectedTime={selectedTime} onTimeChange={handleTimeChange} />
      <div className="mt-4 overflow-y-auto scrollbar-hide" style={{ maxHeight: '70vh' }}>
        <AirData data={data?.air} />
        <SoilData data={data?.soil} />
      </div>
    </div>
  );
};

export default SidebarAnalysis;
