import React from "react";
import HelperForm from "../../components/GeneralSettings/HelperForm";
import { Breadcrumb } from "./Breadcrumb";

const Helper = () => {
    return (
        <div className="w-full p-24" style={{ height: '100vh' }}>
            <div className="h-full flex items-center justify-between flex-col">
                <div className="flex flex-col justify-start w-full">
                    <Breadcrumb title="Impostazioni" route="/settings" backroute="Impostazioni" currentPage="Assistenza" />
                </div>

                <div className="flex w-full justify-center">
                    <HelperForm />
                </div>

                <div></div>
            </div>
        </div>
    )
}

export default Helper;