import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from '../../logo.svg';
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuVariants = {
    open: {
      opacity: 1,
      y: 80,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 15
      },
      display: "block"
    },
    closed: {
      opacity: 0,
      y: 60,
      transitionEnd: {
        display: "none"
      }
    }
  };

  return (
    <nav className="fixed top-0 left-0 right-0 px-5 md:px-20 py-4 text-black shadow-lg glassmorphism">
      <div className="flex justify-between items-center w-full">
        <NavLink to="/" className={({ isActive }) => isActive ? "nav-link-active logo-link" : "logo-link"}>
          <img src={logo} alt="logo" className="w-32" />
        </NavLink>

        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
              className="absolute right-0 mt-12 w-48 bg-white rounded-lg shadow-lg"
            >
              <NavLink to="/services" onClick={() => setIsOpen(false)} className={({ isActive }) => isActive ? "active-link block px-4 py-2 text-sm" : "block px-4 py-2 text-sm"}>
                Servizi
              </NavLink>

              <NavLink to="/insurance" onClick={() => setIsOpen(false)} className={({ isActive }) => isActive ? "active-link block px-4 py-2 text-sm" : "block px-4 py-2 text-sm"}>
                Assicurazioni
              </NavLink>
              <NavLink to="/request-harvest" onClick={() => setIsOpen(false)} className={({ isActive }) => isActive ? "active-link block px-4 py-2 text-sm" : "block px-4 py-2 text-sm"}>
                Richiedi Harvest
              </NavLink>
              <NavLink to="/customer-area" onClick={() => setIsOpen(false)} className={({ isActive }) => isActive ? "active-link block px-4 py-2 text-sm" : "block px-4 py-2 text-sm"}>
                Area Clienti
              </NavLink>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="hidden md:flex justify-end gap-6 px-6">
          <NavLink to="/services" className="nav-link">Servizi</NavLink>
          <NavLink to="/insurance" className="nav-link">Assicurazioni</NavLink>
          <NavLink to="/request-harvest" className="nav-link">Richiedi Harvest</NavLink>
          <NavLink to="/customer-area" className="nav-link">Area Clienti</NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
