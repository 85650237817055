import React from "react";

const TimeSelector = ({ selectedTime, onTimeChange }) => {
  return (
    <div className="flex flex-row justify-between items-center space-x-2 mb-4">
      <button
        className={`px-4 py-2 rounded-md font-semibold ${selectedTime === 'morning' ? 'bg-[#2c3e50] text-white' : 'bg-[#76818d] text-white'
          }`}
        onClick={() => onTimeChange('morning')}
      >
        Mattina
      </button>
      <button
        className={`px-4 py-2 rounded-md font-semibold ${selectedTime === 'afternoon' ? 'bg-[#2c3e50] text-white' : 'bg-[#76818d] text-white'
          }`}
        onClick={() => onTimeChange('afternoon')}
      >
        Pomeriggio
      </button>
      <button
        className={`px-4 py-2 rounded-md font-semibold ${selectedTime === 'evening' ? 'bg-[#2c3e50] text-white' : 'bg-[#76818d] text-white'
          }`}
        onClick={() => onTimeChange('evening')}
      >
        Sera
      </button>
    </div>
  );
};

export default TimeSelector;
