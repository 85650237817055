import React, { useEffect, useState } from 'react';
import CardWeatherHeader from '../CardWeatherHeader/CardWeatherHeader';
import "./WeatherCard.css";
import Connect from '../../services/api';
import { useDashboardContext } from '../../contexts/DashboardContext';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const CertificateRow = ({ data }) => {
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('it-IT', options).replace(' ', ' ');
    };

    return (
        <div className="flex flex-row justify-between items-center p-2 border rounded-xl mt-2">
            <a href={data.url} target="_blank" rel="noreferrer" className="text-primary font-semibold w-1/4 hover:text-gray-800  ">{data.certificateID}<FontAwesomeIcon icon={faUpRightFromSquare} className='ms-2'/></a>
            <div className="text-gray-600 w-1/4">{formatDate(data.timestamp)}</div>
            <div className="text-gray-600 w-1/4">{data.confirmed_round}</div>
        </div>
    );
}

function SevereWeatherCard() {
    const [data, setData] = useState([]);
    const { selectedField } = useDashboardContext();

    const retrieveCertificates = async () => {
        const API = Connect(undefined, undefined, {
            field_id: selectedField.fieldID
        });
        const { data: certificatesData } = await API.get(`/certificates`);
        setData(certificatesData);
    }

    useEffect(() => {
        if (selectedField) {
            retrieveCertificates();
        }
    }, [selectedField]);

    return (
        <div className="weather-card rounded-3xl text-primary min-h-96 min-w-full border-2 p-4">
            <div className="flex justify-center items-center pt-3">    
                <CardWeatherHeader title="Eventi Atmosferici Gravi" showIcon={false}/>
            </div>
            {data.length > 0 ? (
                <div className='mt-6'>
                    <div className='flex flex-row justify-between items-center px-2'>
                        <div className='font-semibold w-1/4'>ID Certificato</div>
                        <div className='font-semibold w-1/4'>Data</div>
                        <div className='font-semibold w-1/4'>Tipo</div>
                    </div>
                    <SimpleBar style={{ maxHeight: '300px' }}>
                        {data.map((certificate, index) => (
                            <CertificateRow key={index} data={certificate} />
                        ))}
                    </SimpleBar>
                </div>
            ) : (
                <div className='flex justify-center items-center h-full w-full font-thin text-xl align-middle pt-9 text-text-secondary'>
                    Nessun evento registrato
                </div>
            )}
        </div>
    );
}

export default SevereWeatherCard;
